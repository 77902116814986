import { render, staticRenderFns } from "./Entity.vue?vue&type=template&id=895928da&scoped=true&"
import script from "./Entity.vue?vue&type=script&lang=js&"
export * from "./Entity.vue?vue&type=script&lang=js&"
import style0 from "./Entity.vue?vue&type=style&index=0&id=895928da&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "895928da",
  null
  
)

export default component.exports