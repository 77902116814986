<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">Solicitação de Transporte</div>
            </div>
            <hr style="margin-top: -4px" v-if="!isLoading" />
            <div class="card-body" v-if="!isLoading">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div
                      class="col-md-1"
                      style="border-right: 1px solid #e4e4e4"
                      v-if="navbar_loaded"
                    >
                      <div
                        v-if="
                          estilo_transporte == 0 &&
                          inverse &&
                          pages_available.includes(2)
                        "
                        class="row pd-item"
                        :class="page == 2 ? 'selected' : ''"
                        @click="(page = 2), verifyAvaliacao()"
                      >
                        <div class="col-md-12">
                          <i class="fas fa-person-booth"></i>
                        </div>
                      </div>
                      <div
                        class="row pd-item"
                        :class="page == 1 ? 'selected' : ''"
                        @click="(page = 1), verifyAvaliacao()"
                        v-if="
                          configuracoes.permissoes == 1 &&
                          pages_available.includes(1)
                        "
                      >
                        <div class="col-md-12">
                          <i class="fas fa-id-card"></i>
                        </div>
                      </div>
                      <div
                        v-if="
                          estilo_transporte == 0 &&
                          !inverse &&
                          configuracoes.permissoes == 1 &&
                          pages_available.includes(2)
                        "
                        class="row pd-item"
                        :class="page == 2 ? 'selected' : ''"
                        @click="(page = 2), verifyAvaliacao()"
                      >
                        <div class="col-md-12">
                          <i class="fas fa-person-booth"></i>
                        </div>
                      </div>
                      <div
                        v-if="
                          configuracoes.permissoes == 1 &&
                          pages_available.includes(3)
                        "
                        class="row pd-item"
                        :class="page == 3 ? 'selected' : ''"
                        @click="
                          (page = 3),
                            getTiposAvaliacaoClinica(),
                            getTiposAuxilioLocomocao(),
                            verifyAvaliacao()
                        "
                      >
                        <div class="col-md-12">
                          <i class="fas fa-tasks"></i>
                        </div>
                      </div>
                      <div
                        v-if="
                          configuracoes.permissoes == 1 &&
                          pages_available.includes(4)
                        "
                        class="row pd-item"
                        :class="page == 4 ? 'selected' : ''"
                        @click="(page = 4), verifyAvaliacao()"
                      >
                        <div class="col-md-12">
                          <i class="fas fa-stethoscope"></i>
                        </div>
                      </div>
                      <div
                        v-if="estilo_transporte == 1"
                        class="row pd-item"
                        :class="page == 5 ? 'selected' : ''"
                        @click="
                          (page = 5),
                            getProdutos(),
                            getTiposCuidado(),
                            verifyAvaliacao()
                        "
                      >
                        <div class="col-md-12">
                          <i class="fas fa-box-open"></i>
                        </div>
                      </div>
                      <div
                        class="row pd-item"
                        :class="page == 6 ? 'selected' : ''"
                        @click="(page = 6), verifyAvaliacao()"
                        v-if="
                          configuracoes.permissoes == 1 &&
                          pages_available.includes(6)
                        "
                      >
                        <div class="col-md-12">
                          <i class="fas fa-calendar-alt"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-11">
                      <section
                        v-show="page == 2"
                        v-if="
                          configuracoes.permissoes == 1 &&
                          estilo_transporte == 0
                        "
                      >
                        <div class="row mb-2">
                          <div class="col-md-12">
                            <div class="b-card-subtitle">
                              Informações do Paciente
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5">
                            <label>CPF</label>
                            <input
                              v-model="entity.paciente.cpf"
                              type="text"
                              class="form-control"
                              v-mask="'999.999.999-99'"
                            />
                          </div>
                          <div class="col-md-1 mt-2">
                            <button class="btn btn-primary" @click="buscarCPF">
                              <i class="fas fa-search"></i>
                            </button>
                          </div>
                          <div class="col-md-5">
                            <label>CNS</label>
                            <input
                              v-model="entity.paciente.cns"
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-1 mt-2">
                            <button class="btn btn-primary">
                              <i class="fas fa-search"></i>
                            </button>
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-md-4">
                            <label><span class="required">*</span> Nome</label>
                            <input
                              v-model="entity.paciente.nome_paciente"
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-4">
                            <label>Nome Social</label>
                            <input
                              v-model="entity.paciente.nome_social"
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-4">
                            <label
                              ><span class="required">*</span> Gênero</label
                            >
                            <select
                              class="form-control"
                              v-model="entity.paciente.sexo"
                            >
                              <option value="M">MASCULINO</option>
                              <option value="F">FEMININO</option>
                              <option value="N">NÃO INFORMADO</option>
                            </select>
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-md-4">
                            <label
                              ><span class="required">*</span> Data de
                              Nascimento</label
                            >
                            <div class="row">
                              <div class="col-8">
                                <input
                                  type="date"
                                  v-model="entity.paciente.data_nascimento"
                                  class="form-control"
                                  v-if="!data_aniversario.data_inexistente"
                                  max="9999-12-31"
                                />
                                <input
                                  class="form-control"
                                  type="text"
                                  value="Não Informado"
                                  disabled
                                  v-else
                                />
                                <span
                                  class="card-text bold"
                                  style="margin-top: 5px"
                                  v-if="
                                    entity.paciente.data_nascimento &&
                                    !data_aniversario.data_inexistente
                                  "
                                  >{{ idade_texto }}</span
                                >
                              </div>
                              <div class="col-4">
                                <button
                                  :class="
                                    'btn btn-' +
                                    (data_aniversario.data_inexistente
                                      ? 'secondary'
                                      : 'primary')
                                  "
                                  style="padding-top: 4px; padding-bottom: 4px"
                                  @click="dataInexistente()"
                                >
                                  {{ data_aniversario.texto_data }}
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <label>Telefone</label>
                            <input
                              v-model="entity.paciente.telefone"
                              type="tel"
                              class="form-control"
                              v-mask="'(99) 99999-9999'"
                            />
                          </div>
                          <div class="col-md-4">
                            <label>Cor/Raça</label>
                            <select
                              class="form-control"
                              v-model="entity.paciente.raca"
                            >
                              <option value="BRANCA">BRANCA</option>
                              <option value="PRETA">PRETA</option>
                              <option value="PARDA">PARDA</option>
                              <option value="AMARELA">AMARELA</option>
                              <option value="INDÍGENA">INDÍGENA</option>
                            </select>
                          </div>
                          <div class="col-md-6 mt-1">
                            <label>Nome do Pai</label>
                            <input
                              v-model="entity.paciente.nome_pai"
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-6 mt-1">
                            <label>Nome da Mãe</label>
                            <input
                              v-model="entity.paciente.nome_mae"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>

                        <div
                          class="row"
                          v-if="configuracoes.dificuldade_acesso == 1"
                        >
                          <div class="col-md-6 mt-1">
                            <label
                              ><span class="required">*</span> Existe
                              dificuldade de acesso ao entrar ou sair ao
                              paciente?</label
                            >
                            <select
                              class="form-control"
                              v-model="
                                entity.paciente.dificuldade_acesso_paciente
                              "
                            >
                              <option value="SIM">Sim</option>
                              <option value="NÃO">Não</option>
                            </select>
                          </div>
                          <div
                            class="col-md-6 mt-1"
                            v-if="
                              entity.paciente.dificuldade_acesso_paciente ==
                              'SIM'
                            "
                          >
                            <label><span class="required">*</span> Qual?</label>
                            <input
                              v-model="
                                entity.paciente
                                  .dificuldade_acesso_paciente_descricao
                              "
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div
                            class="col-md-6 mt-1"
                            @click="getTiposAcompanhante"
                          >
                            <label
                              ><span class="required">*</span> Paciente possui
                              acompanhante?</label
                            >
                            <select
                              class="form-control"
                              v-model="entity.possui_acompanhante"
                            >
                              <option value="SIM">Sim</option>
                              <option value="NÃO">Não</option>
                            </select>
                          </div>
                          <div
                            class="col-md-6 mt-1"
                            v-if="
                              entity.possui_acompanhante === 'SIM' &&
                              entity.acompanhante
                            "
                            style="position: relative; display: inline-block"
                          >
                            <label>Tipo do Acompanhante</label>
                            <!-- <vue-instant
                              :suggestOnAllWords="true"
                              :value="'teste'"
                              :suggestion-attribute="'tipo_acompanhante_nome'"
                              v-model="entity.acompanhante.tipo_acompanhante_id"
                              :disabled="false"
                              :show-autocomplete="true"
                              :autofocus="true"
                              :suggestions="tipos_acompanhante"
                              name="customName"
                              placeholder=""
                              type="google"
                            ></vue-instant> -->

                            <!-- <vue-simple-suggest
                              v-model="entity.acompanhante.tipo_acompanhante_id"
                              :list="tipos_acompanhante"
                              display-attribute="tipo_acompanhante_nome"
                              value-attribute="tipo_acompanhante_nome"
                              :filter-by-query="true"
                            >
                            </vue-simple-suggest> -->
                            <input
                              type="text"
                              v-model="entity.acompanhante.tipo_acompanhante_id"
                              list="tipos_acompanhante"
                              class="form-control"
                            />
                            <datalist
                              style="position: absolute; top: 100%; left: 0"
                              id="tipos_acompanhante"
                            >
                              <option
                                v-for="tipo in tipos_acompanhante"
                                :key="tipo.tipo_acompanhante_id"
                                :value="tipo.tipo_acompanhante_nome"
                              >
                                {{ tipo.tipo_acompanhante_nome }}
                              </option>
                            </datalist>
                          </div>
                        </div>
                        <div
                          class="row mt-1"
                          v-if="
                            entity.possui_acompanhante === 'SIM' &&
                            entity.acompanhante
                          "
                        >
                          <div class="col-md-4">
                            <label>Nome</label>
                            <input
                              v-model="entity.acompanhante.acompanhante_nome"
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-4">
                            <label>Telefone</label>
                            <input
                              v-model="
                                entity.acompanhante.acompanhante_telefone
                              "
                              type="tel"
                              class="form-control"
                              v-mask="'(99) 99999-9999'"
                            />
                          </div>
                          <div class="col-md-4">
                            <label>CPF</label>
                            <input
                              v-model="entity.acompanhante.acompanhante_cpf"
                              type="tel"
                              class="form-control"
                              v-mask="'999.999.999-99'"
                            />
                          </div>
                        </div>
                      </section>
                      <section
                        v-show="page == 1"
                        v-if="
                          configuracoes.permissoes == 1 ||
                          $store.state.user.perfil_id == 2 ||
                          $store.state.user.perfil_id == 5 ||
                          $store.state.user.perfil_id == 15
                        "
                      >
                        <div class="row mb-2">
                          <div class="col-md-12">
                            <div class="b-card-subtitle">
                              Informações da Solicitação
                            </div>
                          </div>
                        </div>
                        <div
                          class="row mt-2"
                          v-if="
                            $store.state.user.perfil_id == 2 ||
                            $store.state.user.perfil_id == 21 ||
                            $store.state.user.perfil_id == 11
                          "
                        >
                          <div class="col-md">
                            <toggle-button
                              v-model="configuracoes.solicitacao_manual"
                              color="#15488A"
                              :height="25"
                            />
                            Para inserir o Número da Solicitação e Data e Hora
                            da Solicitação, clique aqui.
                          </div>
                        </div>
                        <div class="row">
                          <div
                            :class="
                              configuracoes.recorrencia == 0
                                ? 'col-md-2'
                                : 'col-md-4'
                            "
                          >
                            <label>Número da Solicitação</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="entity.numero_solicitacao"
                              :disabled="
                                configuracoes.solicitacao_manual == 1 ||
                                ($store.state.user.perfil_id !== 21 &&
                                  $store.state.user.perfil_id !== 2 &&
                                  $store.state.user.perfil_id !== 11)
                              "
                            />
                          </div>
                          <div
                            :class="
                              configuracoes.recorrencia == 0
                                ? 'col-md-3'
                                : 'col-md-4'
                            "
                          >
                            <label
                              ><span class="required">*</span> Data e Hora da
                              Solicitação</label
                            >
                            <input
                              type="datetime-local"
                              class="form-control"
                              v-model="entity.data_solicitacao"
                              :disabled="
                                configuracoes.solicitacao_manual == 1 ||
                                $store.state.user.perfil_id !== 2
                              "
                              max="9999-12-31T23:59"
                            />
                          </div>

                          <div
                            v-if="configuracoes.recorrencia == 0"
                            :class="
                              configuracoes.recorrencia == 0
                                ? 'col-md-3'
                                : 'col-md-4'
                            "
                          >
                            <label
                              ><span class="required">*</span> Data e Hora do
                              Agendamento</label
                            >
                            <input
                              type="datetime-local"
                              class="form-control"
                              v-model="entity.data_procedimento"
                              max="9999-12-31T23:59"
                              step="1"
                            />
                          </div>

                          <div
                            :class="
                              configuracoes.recorrencia == 0
                                ? 'col-md-4'
                                : 'col-md-4'
                            "
                            v-if="configuracoes.label_tipo_solicitacao == 0"
                          >
                            <label
                              ><span class="required">*</span> Tipo de
                              Solicitação</label
                            >
                            <v-select
                              :options="tipos_transporte"
                              label="tipo_transporte_nome"
                              v-on:input="getMotivos"
                              v-model="entity.tipo_transporte_id"
                              :reduce="(tipo) => tipo.tipo_transporte_id"
                            ></v-select>
                          </div>
                          <div
                            :class="
                              configuracoes.recorrencia == 0
                                ? 'col-md-4'
                                : 'col-md-4'
                            "
                            v-if="configuracoes.label_tipo_solicitacao == 1"
                          >
                            <label
                              ><span class="required">*</span> Tipo de viatura
                              Solicitada</label
                            >
                            <v-select
                              :options="tipos_transporte"
                              label="tipo_transporte_nome"
                              v-on:input="getMotivos"
                              v-model="entity.tipo_transporte_id"
                              :reduce="(tipo) => tipo.tipo_transporte_id"
                            ></v-select>
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-md-12">
                            <label
                              ><span class="required">*</span> Motivo do
                              Transporte</label
                            >
                            <v-select
                              :options="motivo_transporte"
                              label="motivo_transporte_nome"
                              v-model="entity.motivo_transporte_id"
                            >
                              <div slot="no-options">
                                Aguarde, carregando lista de Motivos
                              </div>
                            </v-select>
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-md-12">
                            <label>Observações do Transporte</label>
                            <textarea
                              class="form-control"
                              cols="30"
                              rows="3"
                              v-model="entity.observacao_transporte"
                            ></textarea>
                          </div>
                        </div>

                        <div
                          class="row mt-1"
                          v-if="configuracoes.judicial == 1"
                        >
                          <div class="col-md-6">
                            <toggle-button
                              v-model="entity.flag_mandado_judicial"
                              color="#15488A"
                              :height="25"
                            />
                            É mandado judicial?
                          </div>
                        </div>
                        <div
                          class="row mt-1"
                          v-if="entity.flag_mandado_judicial"
                        >
                          <div class="col-md-3">
                            <label
                              ><span class="required">*</span> Número do
                              processo</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              v-model="entity.mj_numero_processo"
                            />
                          </div>

                          <div class="col-md-3">
                            <label>Data da liberação da vaga</label>
                            <input
                              type="date"
                              class="form-control"
                              v-model="entity.mj_data_liberacao_vaga"
                            />
                          </div>
                          <div class="col-md-3">
                            <label> Data da sentença</label>
                            <input
                              type="date"
                              class="form-control"
                              v-model="entity.mj_data_sentenca"
                            />
                          </div>
                          <div class="col-md-3">
                            <label>Juiz</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="entity.mj_juiz"
                            />
                          </div>
                          <div class="col-md-12 mt-1">
                            <label> Resumo da Sentença</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="entity.mj_resumo_sentenca"
                            />
                          </div>
                        </div>
                        <hr />
                        <div
                          class="row mt-1"
                          v-if="configuracoes.judicial == 1"
                        >
                          <div class="col-md-6">
                            <toggle-button
                              v-model="entity.flag_vaga_zero"
                              color="#15488A"
                              :height="25"
                            />
                            É vaga zero?
                          </div>
                        </div>

                        <div class="row mt-3 mb-2">
                          <div class="col-md-12">
                            <div class="b-card-subtitle">Solicitante</div>
                          </div>
                        </div>

                        <div
                          class="row"
                          v-if="
                            configuracoes.setor_solicitacao == 1 &&
                            configuracoes.telefone_solicitacao == 1 &&
                            configuracoes.profissional_solicitacao == 1
                          "
                        >
                          <div class="col-md-4">
                            <label
                              ><span class="required">*</span> Responsável pela
                              abertura da solicitação</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              v-model="entity.solicitante.profissional_nome"
                              autocomplete="new-password"
                            />
                          </div>
                          <div class="col-md-4">
                            <label>Setor</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="
                                entity.solicitante.unidade_solicitante_setor
                              "
                              autocomplete="new-password"
                            />
                          </div>
                          <div class="col-md-4">
                            <label>Telefone</label>
                            <input
                              type="text"
                              class="form-control"
                              v-mask="'(99) 99999-9999'"
                              v-model="entity.solicitante.telefone"
                              autocomplete="new-password"
                            />
                          </div>
                          <!-- <div class="col-md-4" @click="getCbo">
                            <label>CBO</label>
                            <v-select
                              @click="getCbo"
                              :options="cbos"
                              label="cbo_nome"
                              v-model="entity.cbo_id"
                              :reduce="(cbo) => cbo.cbo_id"
                            >
                              <div slot="no-options">
                                Aguarde, carregando lista de CBO's
                              </div>
                            </v-select>
                          </div> -->
                          <div class="col-md-4">
                            <label>Nome do médico solicitante</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="entity.solicitante.medico_solicitante"
                              autocomplete="new-password"
                            />
                          </div>
                          <!-- <div class="col-md-4 mt-1">
                            <label>ID Funcional</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="entity.solicitante.id_funcional"
                            />
                          </div> -->
                          <div class="col-md-4">
                            <label>Número do Conselho</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="entity.solicitante.numero_conselho"
                              autocomplete="new-password"
                            />
                          </div>
                          <div class="col-md-4">
                            <label>Conselho de Classe</label>
                            <v-select
                              :options="conselho_classe"
                              label="nome"
                              v-model="entity.solicitante.conselho_classe"
                              autocomplete="new-password"
                            >
                              <div slot="no-options">
                                Aguarde, carregando lista de conselhos
                              </div>
                            </v-select>
                          </div>
                          <div class="col-md-6 mb-1">
                            <label>Unidade Solicitante</label>
                            <v-select
                              :options="unidade_saude"
                              :reduce="(unidade_saude) => unidade_saude.id"
                              label="unidade_saude_nome"
                              v-model="
                                entity.solicitante.unidade_solicitante_id
                              "
                              autocomplete="new-password"
                            ></v-select>
                            <!-- <input
                              type="text"
                              v-model="entity.unidade.unidade_saude_nome"
                              v-if="entity.destino_outras_unidades"
                              class="form-control"
                            /> -->
                          </div>
                          <!-- <div class="col-md-4 mt-2" style="margin-top: 10px">
                            <toggle-button
                              v-model="entity.destino_outras_unidades"
                              color="#15488A"
                              :height="25"
                            />
                            Outros?
                          </div> -->
                          <!--   <div
                            class="col-md-4"
                            v-if="entity.destino_outras_unidades"
                          >
                            <label>CEP</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="entity.unidade.cep"
                              v-on:keyup="buscarUnidadeSolicitante"
                              v-mask="'99999-999'"
                            />
                          </div>
                          <div
                            class="col-md-4"
                            v-if="entity.destino_outras_unidades"
                          >
                            <label>Endereço</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="entity.unidade.endereco"
                            />
                          </div>
                          <div
                            class="col-md-4"
                            v-if="entity.destino_outras_unidades"
                          >
                            <label>Número</label>
                            <input
                              type="text"
                              class="form-control"
                              id="inputNumeroUnidade"
                              v-model="entity.unidade.numero"
                            />
                          </div>
                          <div
                            class="col-md-4"
                            v-if="entity.destino_outras_unidades"
                          >
                            <label>Bairro</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="entity.unidade.bairro"
                            />
                          </div>
                          <div
                            class="col-md-4"
                            v-if="entity.destino_outras_unidades"
                          >
                            <label>Complemento</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="entity.unidade.complemento"
                            />
                          </div>
                          <div
                            class="col-md-4"
                            v-if="entity.destino_outras_unidades"
                          >
                            <label>Telefone</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="entity.unidade.telefone"
                            />
                          </div>
                          <div
                            class="col-md-4 mt-1"
                            v-if="entity.destino_outras_unidades"
                          >
                            <label
                              ><span class="required">*</span> Estado</label
                            >
                            <v-select
                              :options="ufs"
                              :reduce="(ufs) => ufs.id"
                              :label="'uf_nome'"
                              v-on:input="getEstadoMunicipioUnidadeSolicitante"
                              v-model="entity.unidade.estado_id"
                              class="vselect"
                            />
                          </div>
                          <div
                            class="col-md-4 mt-1"
                            v-if="entity.destino_outras_unidades"
                          >
                            <label
                              ><span class="required">*</span> Município</label
                            >
                            <select
                              class="form-control"
                              v-model="entity.unidade.municipio_id"
                            >
                              <option
                                v-for="municipio in municipios"
                                :key="municipio.municipio_id"
                                :value="municipio.municipio_id"
                              >
                                {{ municipio.municipio_nome }}
                              </option>
                            </select>
                          </div>
                          <div
                            class="col-md-4 mt-1"
                            v-if="entity.destino_outras_unidades"
                          >
                            <label>Tipos de Unidades de Saude</label>
                            <v-select
                              :options="tipos_unidade_saude"
                              :reduce="
                                (tipos_unidade_saude) => tipos_unidade_saude.id
                              "
                              :label="'tipo_unidade_saude_nome'"
                              v-model="entity.unidade.tipo_unidade_saude_id"
                              class="vselect"
                            />
                          </div> -->
                        </div>

                        <div
                          class="row"
                          v-if="
                            configuracoes.setor_solicitacao == 0 &&
                            configuracoes.telefone_solicitacao == 0 &&
                            configuracoes.profissional_solicitacao == 0
                          "
                        >
                          <div class="col-md-4">
                            <label
                              ><span class="required">*</span> Nome do
                              Profissional</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              v-model="entity.solicitante.profissional_nome"
                            />
                          </div>
                          <div class="col-md-4" @click="getCbo">
                            <label>CBO</label>
                            <v-select
                              @click="getCbo"
                              :options="cbos"
                              label="cbo_nome"
                              v-model="entity.solicitante.cbo_id"
                              :reduce="(cbo) => cbo.cbo_id"
                            >
                              <div slot="no-options">
                                Aguarde, carregando lista de CBO's
                              </div>
                            </v-select>
                          </div>
                          <div class="col-md-4">
                            <label>CNS</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="entity.solicitante.profissional_cns"
                            />
                          </div>
                          <div class="col-md-4 mt-1">
                            <label>ID Funcional</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="entity.solicitante.id_funcional"
                            />
                          </div>
                          <div class="col-md-4 mt-1">
                            <label>Número do Conselho</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="entity.solicitante.numero_conselho"
                            />
                          </div>
                          <div class="col-md-4 mt-1">
                            <label>Conselho de Classe</label>
                            <v-select
                              :options="conselho_classe"
                              label="nome"
                              v-model="entity.solicitante.conselho_classe"
                            >
                              <div slot="no-options">
                                Aguarde, carregando lista de conselhos
                              </div>
                            </v-select>
                          </div>
                          <div class="col-md-6 mt-1">
                            <label>Unidade Solicitante</label>
                            <v-select
                              :options="unidade_saude"
                              :reduce="(unidade_saude) => unidade_saude.id"
                              label="unidade_saude_nome"
                              v-model="
                                entity.solicitante.unidade_solicitante_id
                              "
                            ></v-select>
                          </div>

                          <div class="col-md-3 mt-1">
                            <label>Setor</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="
                                entity.solicitante.unidade_solicitante_setor
                              "
                            />
                          </div>
                          <div class="col-md-3 mt-1">
                            <label>Telefone</label>
                            <input
                              type="text"
                              class="form-control"
                              v-mask="'(99) 99999-9999'"
                              v-model="entity.solicitante.telefone"
                            />
                          </div>
                        </div>

                        <div class="row mb-2 mt-3">
                          <div class="col-md-12">
                            <div class="b-card-subtitle">
                              Endereço de Origem
                            </div>
                          </div>
                        </div>

                        <div class="row mt-1">
                          <div class="col-md-4">
                            <button
                              v-if="
                                entity.tipo_transporte_id == 1 &&
                                configuracoes.recorrencia == 1
                              "
                              class="btn btn-back"
                              @click="getEnderecoPaciente"
                            >
                              Utilizar Endereço do Paciente
                            </button>
                          </div>
                        </div>

                        <div class="row mt-1">
                          <div class="col-md-6" @click="getUnidades">
                            <label
                              ><span class="required">*</span> Tipo de
                              Endereço</label
                            >
                            <select
                              class="form-control"
                              v-model="entity.origem.tipo_estabelecimento_id"
                            >
                              <option
                                v-for="tipo in tipos_domicilio"
                                :key="tipo.tipo_domicilio_id"
                                :value="tipo.tipo_domicilio_id"
                              >
                                {{ tipo.tipo_domicilio_nome }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div
                          class="row mt-1"
                          v-if="entity.origem.tipo_estabelecimento_id == 6"
                        >
                          <div class="col-md-6">
                            <label>Unidade de Origem</label>
                            <v-select
                              v-if="!origem_outros"
                              :options="unidade_saude"
                              label="unidade_saude_nome"
                              v-model="entity.origem.unidade_saude_id"
                              v-on:input="selectUnidadeOrigem"
                            >
                              <div slot="no-options">
                                Aguarde, carregando lista de Unidades
                              </div>
                            </v-select>
                            <input
                              type="text"
                              v-model="entity.origem.estabelecimento_nome"
                              v-if="origem_outros"
                              class="form-control"
                              disabled
                            />
                          </div>
                          <div class="col-md-6 d-flex align-items-center mt-2">
                            <toggle-button
                              v-show="entity.id"
                              v-model="origem_outros"
                              color="#15488A"
                              :height="25"
                            />
                            <span class="ml-1" v-show="entity.id"
                              >Alterar origem?</span
                            >
                            <button
                              class="btn btn-add ml-2"
                              @click="atualizarOrigem"
                            >
                              Atualizar lista
                            </button>
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-md-2">
                            <label>CEP da Origem</label>
                            <input
                              v-model="entity.origem.cep"
                              type="tel"
                              class="form-control"
                              v-on:keyup="buscarOrigem"
                              v-mask="'99999-999'"
                            />
                          </div>
                          <div class="col-md-5">
                            <label
                              ><span class="required">*</span> Logradouro</label
                            >
                            <input
                              v-model="entity.origem.endereco"
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-2">
                            <label
                              ><span class="required">*</span> Número</label
                            >
                            <input
                              id="inputNumero"
                              v-model="entity.origem.numero"
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-3">
                            <label>Bairro</label>
                            <input
                              v-model="entity.origem.bairro"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-2 mt-1">
                            <label
                              ><span class="required">*</span> Estado</label
                            >
                            <v-select
                              :options="ufs"
                              :reduce="(ufs) => ufs.id"
                              :label="'uf_nome'"
                              v-on:input="getEstadoMunicipioOrigem"
                              v-model="entity.origem.estado_id"
                              class="vselect"
                            />
                          </div>
                          <div class="col-md-4 mt-1">
                            <label
                              ><span class="required">*</span> Município</label
                            >
                            <v-select
                              :options="municipios_origem"
                              :reduce="(municipios) => municipios.id"
                              :label="'municipio_nome'"
                              v-model="entity.origem.municipio_id"
                              class="vselect"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4 mt-1">
                            <label>Complemento</label>
                            <input
                              v-model="entity.origem.complemento"
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-4 mt-1">
                            <label>Ponto de Referência</label>
                            <input
                              v-model="entity.origem.ponto_referencia"
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-4 mt-1">
                            <label>Telefone</label>
                            <input
                              v-model="entity.origem.telefone"
                              type="tel"
                              class="form-control"
                              v-mask="'(99) 99999-9999'"
                            />
                          </div>
                        </div>
                        <div
                          class="row"
                          v-if="configuracoes.dificuldade_acesso == 1"
                        >
                          <div class="col-md-6 mt-1">
                            <label
                              ><span class="required">*</span> Possui
                              dificuldade de acesso ao local?</label
                            >
                            <select
                              class="form-control"
                              v-model="entity.origem.dificuldade_acesso"
                            >
                              <option value="SIM">Sim</option>
                              <option value="NÃO">Não</option>
                            </select>
                          </div>
                          <div
                            class="col-md-6 mt-1"
                            v-if="entity.origem.dificuldade_acesso == 'SIM'"
                          >
                            <label><span class="required">*</span> Qual?</label>
                            <input
                              v-model="
                                entity.origem.dificuldade_acesso_descricao
                              "
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div
                          class="row mt-1"
                          v-if="entity.origem.tipo_estabelecimento_id == 6"
                        >
                          <div class="col-md-4">
                            <label>Setor</label>
                            <input
                              v-model="entity.origem.setor"
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-4">
                            <label>Pavimento</label>
                            <input
                              v-model="entity.origem.pavimento"
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-4">
                            <label>Leito</label>
                            <input
                              v-model="entity.origem.leito"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>

                        <div class="row mt-3 mb-2">
                          <div class="col-md-12">
                            <div class="b-card-subtitle">
                              Endereço de Destino
                            </div>
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-md-6">
                            <label
                              ><span class="required">*</span> Tipo de
                              Endereço</label
                            >
                            <select
                              class="form-control"
                              v-model="entity.destino.tipo_estabelecimento_id"
                            >
                              <option
                                v-for="tipo in tipos_domicilio"
                                :key="tipo.tipo_domicilio_id"
                                :value="tipo.tipo_domicilio_id"
                              >
                                {{ tipo.tipo_domicilio_nome }}
                              </option>
                            </select>
                          </div>
                          <div
                            class="col-md-6"
                            v-if="configuracoes.tipo_destino == 1"
                          >
                            <label>Tipo de Destino</label>
                            <select
                              class="form-control"
                              v-model="entity.tipo_destino"
                            >
                              <option value="Municipal">Municipal</option>
                              <option value="Intermunicipal">
                                Intermunicipal
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-md-6">
                            <label>Unidade de Destino</label>
                            <v-select
                              :options="unidade_saude"
                              v-if="!destino_outros"
                              label="unidade_saude_nome"
                              v-model="entity.destino.unidade_saude_id"
                              v-on:input="selectUnidadeDestino"
                            ></v-select>
                            <input
                              type="text"
                              v-model="entity.destino.estabelecimento_nome"
                              v-if="destino_outros"
                              class="form-control"
                              disabled
                            />
                          </div>
                          <div class="col-md-6 d-flex align-items-center mt-2">
                            <toggle-button
                              v-show="entity.id"
                              v-model="destino_outros"
                              color="#15488A"
                              :height="25"
                            />
                            <span class="ml-1" v-show="entity.id"
                              >Alterar destino?</span
                            >
                            <button
                              class="btn btn-add ml-2"
                              @click="atualizarDestino"
                            >
                              Atualizar lista
                            </button>
                          </div>
                        </div>

                        <div class="row mt-1">
                          <div class="col-md-2">
                            <label>CEP do Destino</label>
                            <input
                              type="text"
                              class="form-control"
                              id="cepUnidade"
                              v-mask="'99999-999'"
                              v-on:keyup="buscarDestino"
                              v-model="entity.destino.cep"
                            />
                          </div>
                          <div class="col-md-5">
                            <label
                              ><span class="required">*</span> Logadouro</label
                            >
                            <input
                              v-model="entity.destino.endereco"
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-2">
                            <label
                              ><span class="required">*</span> Número</label
                            >
                            <input
                              id="inputNumeroDestino"
                              v-model="entity.destino.numero"
                              type="text"
                              class="form-control"
                              maxlength="100"
                            />
                          </div>
                          <div class="col-md-3">
                            <label>Bairro</label>
                            <input
                              v-model="entity.destino.bairro"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-2 mt-1">
                            <label
                              ><span class="required">*</span> Estado</label
                            >
                            <v-select
                              :options="ufs"
                              :reduce="(ufs) => ufs.id"
                              :label="'uf_nome'"
                              v-on:input="getEstadoMunicipioDestino"
                              v-model="entity.destino.estado_id"
                              class="vselect"
                            />
                          </div>
                          <div class="col-md-4 mt-1">
                            <label
                              ><span class="required">*</span> Município</label
                            >
                            <v-select
                              :options="municipios_destino"
                              :reduce="(municipios) => municipios.id"
                              :label="'municipio_nome'"
                              v-model="entity.destino.municipio_id"
                              class="vselect"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4 mt-1">
                            <label>Complemento</label>
                            <input
                              v-model="entity.destino.complemento"
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-4 mt-1">
                            <label>Ponto de Referência</label>
                            <input
                              v-model="entity.destino.ponto_referencia"
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-4 mt-1">
                            <label>Telefone</label>
                            <input
                              v-model="entity.destino.telefone"
                              type="tel"
                              class="form-control"
                              v-mask="'(99) 99999-9999'"
                            />
                          </div>
                        </div>

                        <div
                          class="row mt-1"
                          v-if="entity.destino.tipo_estabelecimento_id == 6"
                        >
                          <div class="col-md-4">
                            <label>Setor</label>
                            <input
                              v-model="entity.destino.setor"
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-4">
                            <label>Pavimento</label>
                            <input
                              v-model="entity.destino.pavimento"
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-4">
                            <label>Leito</label>
                            <input
                              v-model="entity.destino.leito"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>

                        <div class="row mt-1">
                          <div class="col-md-6">
                            <label
                              >Nome do Profissional Responsável pela
                              Autorização</label
                            >
                            <input
                              v-model="
                                entity.destino.nome_profissional_autorizacao
                              "
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-6">
                            <label>SER/SISREG</label>
                            <input
                              v-model="entity.destino.cer_sisgero"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>

                        <div class="row mt-1">
                          <div class="col-md-6">
                            <div class="custom-control custom-switch">
                              <input
                                autocomplete="new-password"
                                v-model="entity.flag_retorna_origem"
                                placeholder=""
                                type="checkbox"
                                class="custom-control-input"
                                id="customSwitch1"
                              />
                              <label
                                class="custom-control-label"
                                for="customSwitch1"
                                >Retorna para Origem?</label
                              >
                            </div>
                          </div>
                        </div>
                      </section>
                      <section
                        v-show="page == 3"
                        v-if="
                          configuracoes.permissoes == 1 &&
                          estilo_transporte == 0
                        "
                      >
                        <section v-show="configuracoes.cid_avaliacao == 1">
                          <div class="row mb-2 mt-2">
                            <div class="col-md-12">
                              <div class="b-card-subtitle">
                                Avaliações Clínicas do Paciente
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-12">
                              <textarea
                                v-model="avaliacao_clinica"
                                class="form-control"
                                id=""
                                cols="20"
                                rows="3"
                              ></textarea>
                            </div>
                            <div class="col-md-12 mt-1 text-right">
                              <button
                                class="btn btn-primary"
                                @click="incluirAvaliacao"
                              >
                                <i class="fas fa-plus"></i> Incluir Avaliação
                              </button>
                            </div>
                          </div>
                          <div
                            class="row mt-4"
                            v-if="entity.avaliacoes_clinicas.length > 0"
                          >
                            <div class="col-md-12">
                              <table
                                class="table table-bordered"
                                style="width: 100%"
                              >
                                <thead>
                                  <tr>
                                    <th scope="col" style="width: 52%">
                                      Avaliação
                                    </th>
                                    <th scope="col" style="width: 20%">
                                      Usuário
                                    </th>
                                    <th scope="col" style="width: 23%">
                                      Horário
                                    </th>
                                    <th
                                      v-if="
                                        configuracoes.deletar_avaliacao == 0
                                      "
                                      scope="col"
                                      style="width: 5%"
                                    >
                                      Ações
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(
                                      avaliacao, index
                                    ) in entity.avaliacoes_clinicas"
                                    :key="
                                      moment(avaliacao.datetime).format(
                                        'HHmmss'
                                      )
                                    "
                                  >
                                    <td>{{ avaliacao.avaliacao }}</td>
                                    <td>
                                      {{ avaliacao.usuario.usuario_nome }}
                                    </td>
                                    <td>
                                      {{
                                        moment(avaliacao.datetime).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )
                                      }}
                                    </td>
                                    <td
                                      v-if="
                                        configuracoes.deletar_avaliacao == 0
                                      "
                                      class="centered clickable"
                                      @click="removerAvaliacao(index)"
                                    >
                                      <i class="fas fa-trash-alt"></i>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </section>
                        <div class="row mb-2 mt-1">
                          <div class="col-md-12">
                            <div class="b-card-subtitle">
                              Vtr Tripulada pelo Médico
                            </div>
                          </div>
                        </div>
                        <div
                          class="row mb-1"
                          v-if="
                            $store.state.user.perfil_id == 6 ||
                            $store.state.user.perfil_id == 2 ||
                            $store.state.user.perfil_id == 21
                          "
                        >
                          <div class="col-md-6">
                            <toggle-button
                              v-model="entity.flag_medico_unidade"
                              color="#15488A"
                              :height="25"
                            />
                            Vtr será tripulada pelo médico da unidade
                            hospitalar?
                          </div>
                        </div>
                        <div class="row mt-1" v-if="entity.flag_medico_unidade">
                          <div class="col-md-6">
                            <label
                              ><span class="required">*</span> Nome do médico da
                              unidade</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              v-model="entity.medico_unidade_tripulado"
                            />
                          </div>

                          <div class="col-md-6">
                            <label>CRM do médico da unidade</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="entity.crm_medico_tripulado"
                            />
                          </div>
                        </div>

                        <div class="row mb-2">
                          <div class="col-md-12">
                            <div class="b-card-subtitle">
                              Data de de entrada na unidade solicitante
                            </div>
                          </div>
                        </div>
                        <div
                          class="row mb-1"
                          v-if="configuracoes.sinais_vitais == 1"
                        >
                          <div class="col-md-4" v-if="entity.paciente">
                            <label for="">Data de entrada</label>
                            <input
                              type="datetime-local"
                              class="form-control"
                              v-model="entity.paciente.data_entrada_unidade"
                              max="9999-12-31"
                            />
                          </div>
                        </div>

                        <div class="row mb-2">
                          <div class="col-md-12">
                            <div class="b-card-subtitle">
                              Descrição do Quadro Clínico
                            </div>
                          </div>
                        </div>

                        <div
                          class="row mt-3"
                          v-if="!configuracoes.sinais_vitais"
                        >
                          <div class="col-md-12">
                            <h3>Antropometria</h3>
                          </div>

                          <div class="col-md-2">
                            <label for="">Peso</label>
                            <input
                              type="number"
                              class="form-control"
                              id=""
                              v-model="entity.paciente.peso"
                            />
                          </div>
                          <div class="col-md-2">
                            <label for="">Altura</label>
                            <input
                              type="text"
                              class="form-control"
                              id=""
                              v-model="entity.paciente.altura"
                              v-mask="'#.##'"
                            />
                          </div>
                          <div class="col-md-2">
                            <label for="">IMC</label>
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              :value="imc ? imc : ''"
                            />
                          </div>
                          <div class="col-ml-1" style="margin-top: 5px">
                            <table class="tabela-imc">
                              <tr>
                                <th
                                  v-if="
                                    entity.paciente.peso &&
                                    entity.paciente.altura &&
                                    imc
                                  "
                                >
                                  Classificação
                                </th>
                                <th
                                  v-if="
                                    entity.paciente.peso &&
                                    entity.paciente.altura &&
                                    imc
                                  "
                                >
                                  IMC
                                </th>
                              </tr>
                              <tr
                                style="background-color: #ffff00"
                                v-if="
                                  imc < 18.5 &&
                                  entity.paciente.peso &&
                                  entity.paciente.altura
                                "
                              >
                                <td>Abaixo do peso</td>
                                <td>Menor que 18,5</td>
                              </tr>
                              <tr
                                style="background-color: #add8e6"
                                v-if="
                                  imc >= 18.5 &&
                                  imc <= 24.9 &&
                                  entity.paciente.peso &&
                                  entity.paciente.altura
                                "
                              >
                                <td>Peso normal</td>
                                <td>18,5 - 24,9</td>
                              </tr>
                              <tr
                                style="background-color: #ffa500"
                                v-if="
                                  imc >= 25 &&
                                  imc <= 29.9 &&
                                  entity.paciente.peso &&
                                  entity.paciente.altura
                                "
                              >
                                <td>Sobrepeso</td>
                                <td>25 - 29,9</td>
                              </tr>
                              <tr
                                class="obesidade1"
                                v-if="
                                  imc >= 30 &&
                                  imc <= 34.9 &&
                                  entity.paciente.peso &&
                                  entity.paciente.altura
                                "
                              >
                                "
                                <td>Obesidade grau 1</td>
                                <td>30 - 34,9</td>
                              </tr>
                              <tr
                                class="obesidade2"
                                v-if="
                                  imc >= 35 &&
                                  imc <= 39.9 &&
                                  entity.paciente.peso &&
                                  entity.paciente.altura
                                "
                              >
                                <td>Obesidade grau 2</td>
                                <td>35 - 39,9</td>
                              </tr>
                              <tr
                                class="obesidade3"
                                v-if="
                                  imc >= 40 &&
                                  entity.paciente.peso &&
                                  entity.paciente.altura
                                "
                              >
                                <td>Obesidade grau 3</td>
                                <td>Maior ou igual a 40</td>
                              </tr>
                            </table>
                          </div>
                        </div>

                        <div
                          class="row mb-1"
                          v-if="configuracoes.sinais_vitais == 1"
                        >
                          <div class="col-md-4">
                            <label for="">Estado Neurológico</label>
                            <select
                              class="form-control"
                              v-model="entity.paciente.estado_neurologico"
                            >
                              <option value=""></option>
                              <option value="Alerta">Alerta</option>
                              <option value="Sonolento">Sonolento</option>
                              <option value="Torporoso">Torporoso</option>
                              <option value="Sedado">Sedado</option>
                              <option value="Inconsciente">Inconsciente</option>
                            </select>
                          </div>
                          <div class="col-md-3">
                            <label for="">Escala de Glasgow</label>
                            <input
                              data-toggle="modal"
                              data-target="#glasgowModalCenter"
                              readonly
                              type="text"
                              class="form-control"
                              id=""
                              v-model="glasgowCount"
                            />
                          </div>
                          <div class="col-md-4" style="margin-top: 30px">
                            <span v-html="glasgowLabel"></span>
                          </div>
                        </div>

                        <div
                          class="row mt-4"
                          v-if="configuracoes.sinais_vitais == 1"
                        >
                          <div class="col-md-12">
                            <h3>Sinais vitais</h3>
                          </div>
                          <div class="col-md-12 mt-1">
                            <div class="row">
                              <div class="col-md-4 bold">Pressão arterial</div>
                              <div class="col-md-2 bold">FC</div>
                              <div class="col-md-2 bold">FR</div>
                              <div class="col-md-2 bold">Temperatura</div>
                              <div class="col-md-2 bold"></div>
                            </div>
                            <div class="row">
                              <div class="col-md-2">
                                <label for="pa_sistolica">Sist. (mmHg):</label>
                                <input
                                  type="number"
                                  id="pa_sistolica"
                                  v-model.number="entity.paciente.pa_sistolica"
                                  class="form-control"
                                  placeholder="Máximo de 300"
                                />
                              </div>
                              <div class="col-md-2">
                                <label for="">Diast. (mmHg)</label>
                                <input
                                  type="number"
                                  class="form-control"
                                  v-model="entity.paciente.pa_diastolica"
                                  placeholder="Máximo de 300"
                                />
                              </div>
                              <div class="col-md-2">
                                <label for="">(bpm)</label>
                                <input
                                  type="number"
                                  class="form-control"
                                  placeholder="Máximo de 300"
                                  v-model="entity.paciente.fc"
                                />
                              </div>
                              <div class="col-md-2">
                                <label for="">(rpm)</label>
                                <input
                                  type="number"
                                  placeholder="Máximo de 300"
                                  class="form-control"
                                  v-model="entity.paciente.fr"
                                />
                              </div>
                              <div class="col-md-2">
                                <label for="">C°</label>
                                <input
                                  type="number"
                                  class="form-control"
                                  v-model="entity.paciente.temperatura"
                                  placeholder="Máximo de 60"
                                />
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-2">
                                <label for="">Saturação</label>
                                <input
                                  type="number"
                                  @input="limitarValor"
                                  placeholder="Máximo de 100"
                                  class="form-control"
                                  v-model="entity.paciente.saturacao"
                                />
                              </div>
                              <div class="col-md-3" style="margin-top: 30px">
                                <span
                                  class="boxSelecionavel"
                                  @click="
                                    entity.paciente.saturacao_tipo =
                                      'Ar Atmosférico'
                                  "
                                  :class="
                                    entity.paciente.saturacao_tipo ==
                                    'Ar Atmosférico'
                                      ? 'ativo'
                                      : ''
                                  "
                                  >Ar Atmosférico</span
                                >
                                <span
                                  class="boxSelecionavel"
                                  @click="
                                    entity.paciente.saturacao_tipo =
                                      'Em terapia de O2'
                                  "
                                  :class="
                                    entity.paciente.saturacao_tipo ==
                                    'Em terapia de O2'
                                      ? 'ativo'
                                      : ''
                                  "
                                  >Em terapia de O2</span
                                >
                              </div>
                            </div>
                            <div
                              class="row mt-1"
                              v-if="configuracoes.sinais_vitais == 1"
                            >
                              <div class="col-md-2">
                                <label for="">Glicemia</label>
                                <input
                                  type="number"
                                  @input="limitarValor"
                                  placeholder="Máximo de 1000"
                                  class="form-control"
                                  v-model="entity.paciente.glicemia"
                                />
                              </div>
                              <div class="col-md-3" style="margin-top: 30px">
                                <span
                                  class="boxSelecionavel"
                                  @click="
                                    entity.paciente.glicemia_tipo = 'Em Jejum'
                                  "
                                  :class="
                                    entity.paciente.glicemia_tipo == 'Em Jejum'
                                      ? 'ativo'
                                      : ''
                                  "
                                  >Em Jejum</span
                                >
                                <span
                                  class="boxSelecionavel"
                                  @click="
                                    entity.paciente.glicemia_tipo =
                                      'Pós-prandial'
                                  "
                                  :class="
                                    entity.paciente.glicemia_tipo ==
                                    'Pós-prandial'
                                      ? 'ativo'
                                      : ''
                                  "
                                  >Pós-prandial</span
                                >
                              </div>
                            </div>
                            <div class="row mt-2" v-if="diffIdadeDias <= 29">
                              <div class="col-md-2">
                                <label for="">APGAR (1 minuto)</label>
                                <input
                                  type="number"
                                  @input="limitarValor"
                                  placeholder="Máximo de 10"
                                  class="form-control"
                                  v-model="entity.paciente.apgar_1"
                                />
                              </div>
                              <div class="col-md-2">
                                <label for="">APGAR (5 minutos)</label>
                                <input
                                  type="number"
                                  @input="limitarValor"
                                  placeholder="Máximo de 10"
                                  class="form-control"
                                  v-model="entity.paciente.apgar_2"
                                />
                              </div>
                            </div>
                            <div class="row mt-2" v-if="diffIdadeDias <= 29">
                              <div class="col-md-2">
                                <label for="">Idade Gestacional</label>
                                <input
                                  type="number"
                                  @input="limitarValor"
                                  placeholder="Máximo de 45"
                                  class="form-control"
                                  v-model="entity.paciente.idade_gestacional"
                                />
                              </div>
                            </div>
                            <div class="row mt-3">
                              <div class="col-md-12">
                                <h3>Antropometria</h3>
                              </div>

                              <div class="col-md-2">
                                <label for="">Peso</label>
                                <input
                                  type="number"
                                  class="form-control"
                                  id=""
                                  v-model="entity.paciente.peso"
                                />
                              </div>
                              <div class="col-md-2">
                                <label for="">Altura</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id=""
                                  v-model="entity.paciente.altura"
                                  v-mask="'#.##'"
                                />
                              </div>
                              <div class="col-md-2 mt-2">
                                <toggle-button
                                  v-model="
                                    entity.paciente.transporte_bariatrico
                                  "
                                  color="#15488A"
                                  :height="25"
                                />
                                Precisa de Transporte Bariatrico?
                              </div>
                              <!-- <div class="col-md-2">
                                <label for="">IMC</label>
                                <input
                                  disabled
                                  type="text"
                                  class="form-control"
                                  :value="imc ? imc : ''"
                                />
                              </div> -->
                              <!-- <div class="col-ml-1" style="margin-top: 5px">
                                <table class="tabela-imc">
                                  <tr>
                                    <th
                                      v-if="
                                        entity.paciente.peso &&
                                        entity.paciente.altura &&
                                        imc
                                      "
                                    >
                                      Classificação
                                    </th>
                                    <th
                                      v-if="
                                        entity.paciente.peso &&
                                        entity.paciente.altura &&
                                        imc
                                      "
                                    >
                                      IMC
                                    </th>
                                  </tr>
                                  <tr
                                    style="
                                      background-color: #ffff00;
                                      color: #000000;
                                    "
                                    v-if="
                                      imc < 18.5 &&
                                      entity.paciente.peso &&
                                      entity.paciente.altura
                                    "
                                  >
                                    <td>Abaixo do peso</td>
                                    <td>Menor que 18,5</td>
                                  </tr>
                                  <tr
                                    style="
                                      background-color: #add8e6;
                                      color: #000000;
                                    "
                                    v-if="
                                      imc >= 18.5 &&
                                      imc <= 24.9 &&
                                      entity.paciente.peso &&
                                      entity.paciente.altura
                                    "
                                  >
                                    <td>Peso normal</td>
                                    <td>18,5 - 24,9</td>
                                  </tr>
                                  <tr
                                    style="
                                      background-color: #ffa500;
                                      color: #000000;
                                    "
                                    v-if="
                                      imc >= 25 &&
                                      imc <= 29.9 &&
                                      entity.paciente.peso &&
                                      entity.paciente.altura
                                    "
                                  >
                                    <td>Sobrepeso</td>
                                    <td>25 - 29,9</td>
                                  </tr>
                                  <tr
                                    class="obesidade1"
                                    v-if="
                                      imc >= 30 &&
                                      imc <= 34.9 &&
                                      entity.paciente.peso &&
                                      entity.paciente.altura
                                    "
                                  >
                                    <td>Obesidade grau 1</td>
                                    <td>30 - 34,9</td>
                                  </tr>
                                  <tr
                                    class="obesidade2"
                                    v-if="
                                      imc >= 35 &&
                                      imc <= 39.9 &&
                                      entity.paciente.peso &&
                                      entity.paciente.altura
                                    "
                                  >
                                    <td>Obesidade grau 2</td>
                                    <td>35 - 39,9</td>
                                  </tr>
                                  <tr
                                    class="obesidade3"
                                    v-if="
                                      imc >= 40 &&
                                      entity.paciente.peso &&
                                      entity.paciente.altura
                                    "
                                  >
                                    <td>Obesidade grau 3</td>
                                    <td>Maior ou igual a 40</td>
                                  </tr>
                                </table>
                              </div>
                              <div class="col-md-12 mt-1">
                                <span
                                  v-if="imc >= 30"
                                  style="font-weight: bold; color: red"
                                  >*Indicação de Transporte Bariatrico</span
                                >
                              </div> -->
                            </div>

                            <div class="row mt-4">
                              <div class="col-md-4">
                                <label for="">Suporte de O²</label>
                                <select
                                  class="form-control"
                                  v-model="entity.paciente.suporte_o2"
                                >
                                  <option value=""></option>
                                  <option value="Catete Tipo Óculos">
                                    Catete Tipo Óculos
                                  </option>
                                  <option value="Mascara Reservatório">
                                    Mascara Reservatório
                                  </option>
                                  <option value="V.M">V.M</option>
                                  <option value="HOOD">HOOD</option>
                                  <option value="Cpap Nasal">Cpap Nasal</option>
                                </select>
                              </div>
                              <div class="col-md-4">
                                <label for="">Quantos litros de O²?</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="entity.paciente.litros_o2"
                                />
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-4">
                                <label for="">Tipos de Acesso Venoso</label>
                                <select
                                  class="form-control"
                                  v-model="entity.paciente.acesso_venoso"
                                >
                                  <option value=""></option>
                                  <option value="MSD">MSD</option>
                                  <option value="MSE">MSE</option>
                                  <option value="Jugular">Jugular</option>
                                  <option value="Subclávia">Subclávia</option>
                                  <option value="Cefálica">Cefálica</option>
                                  <option value="Umbilical">Umbilical</option>
                                  <option value="Acesso Femoral">
                                    Acesso Femoral
                                  </option>
                                  <option value="Outros">Outros</option>
                                </select>
                              </div>
                              <div
                                class="col-md-8"
                                v-if="entity.paciente.acesso_venoso == 'Outros'"
                              >
                                <label for="">Qual?</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="entity.paciente.acesso_venoso_outros"
                                />
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-4">
                                <toggle-button
                                  v-model="entity.paciente.incubadora"
                                  color="#15488A"
                                  :height="25"
                                />
                                Transporte em Incubadora?
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-3">
                                <toggle-button
                                  v-model="entity.paciente.flg_bomba_infusora"
                                  color="#15488A"
                                  :height="25"
                                  v-on:input="
                                    entity.paciente.flg_bomba_infusora == false
                                      ? (entity.paciente.bomba_infusora = '')
                                      : ''
                                  "
                                />
                                Uso de Bomba Infusora?
                              </div>
                              <div
                                class="col-md-3"
                                v-show="entity.paciente.flg_bomba_infusora"
                              >
                                <label for="">Quantidade</label>
                                <select
                                  class="form-control"
                                  v-model="entity.paciente.bomba_infusora"
                                >
                                  <option value=""></option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                </select>
                              </div>
                              <div
                                class="col-md-3"
                                v-if="entity.paciente.flg_bomba_infusora"
                              >
                                <label for="">Medicamentos</label>

                                <v-select
                                  :options="medicamentos"
                                  label=""
                                  v-model="
                                    entity.paciente.bomba_infusora_medicamentos
                                  "
                                  multiple
                                ></v-select>
                              </div>
                              <div
                                class="col-md-3"
                                v-show="entity.paciente.flg_bomba_infusora"
                              >
                                <label for="">ML/H</label>
                                <input
                                  class="form-control"
                                  v-model="entity.paciente.mlh"
                                  type="text"
                                  autocomplete="new-password"
                                />
                              </div>
                              <div
                                class="col-md-12 mt-1"
                                v-show="entity.paciente.flg_bomba_infusora"
                              >
                                <label for="">Outras Drogas</label>
                                <textarea
                                  v-model="entity.paciente.outras_drogas"
                                  class="form-control"
                                  id=""
                                  cols="20"
                                  rows="3"
                                ></textarea>
                              </div>
                              <div
                                class="col-md-12 mt-1"
                                v-show="entity.paciente.flg_bomba_infusora"
                              >
                                <label for="">Velocidade de Infusão</label>
                                <input
                                  class="form-control"
                                  v-model="entity.paciente.velocidade_infusao"
                                  type="text"
                                  autocomplete="new-password"
                                />
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-4">
                                <label>Outros Dispositivos</label>
                                <select
                                  class="form-control"
                                  v-model="entity.paciente.outros_dispositivos"
                                >
                                  <option value=""></option>
                                  <option value="Cateter Vesical">
                                    Cateter Vesical
                                  </option>
                                  <option value="Cateter Nasogástrico">
                                    Cateter Nasogástrico
                                  </option>
                                  <option value="Cateter Umbilical ">
                                    Cateter Umbilical
                                  </option>
                                  <option value="Dreno">Dreno</option>
                                  <option value="Ostomia">Ostomia</option>
                                  <option value="PICC">PICC</option>
                                  <option value="Todos">Todos</option>
                                </select>
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-2">
                                <toggle-button
                                  v-model="entity.paciente.flg_alergias"
                                  color="#15488A"
                                  :height="25"
                                  v-on:input="
                                    entity.paciente.flg_alergias == false
                                      ? (entity.paciente.alergias = '')
                                      : ''
                                  "
                                />
                                Alergias
                              </div>
                              <div
                                class="col-md-8"
                                v-show="entity.paciente.flg_alergias == true"
                              >
                                <label for="">Qual?</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="entity.paciente.alergias"
                                />
                              </div>
                            </div>
                            <!-- <div class="row mt-2">
                              <div class="col-md-2">
                                <toggle-button
                                  v-model="entity.paciente.covid"
                                  color="#15488A"
                                  :height="25"
                                />
                                Covid-19
                              </div>
                            </div>
                            <div class="row mt-2" v-if="entity.paciente.covid">
                              <div class="col-md-2">
                                <label for="">Isolamento</label>
                                <div class="form-check">
                                  <input
                                    v-model="
                                      entity.paciente.covid_isolamento_contato
                                    "
                                    class="form-check-input"
                                    type="checkbox"
                                    value="true"
                                    id="Contato"
                                  />
                                  <label
                                    class="form-check-label"
                                    for="Contato"
                                    style="font-size: 16px"
                                  >
                                    Contato
                                  </label>
                                </div>
                                <div class="form-check">
                                  <input
                                    v-model="
                                      entity.paciente
                                        .covid_isolamento_respiratorio
                                    "
                                    class="form-check-input"
                                    type="checkbox"
                                    value="true"
                                    id="Respiratório"
                                  />
                                  <label
                                    class="form-check-label"
                                    for="Respiratório"
                                    style="font-size: 16px"
                                  >
                                    Respiratório
                                  </label>
                                </div>
                              </div>
                            </div> -->
                            <!-- <div class="col-md-3">
                                <label for="">Acesso Venoso Periférico</label>
                                <input
                                  type="number"
                                  placeholder="Local"
                                  class="form-control"
                                  v-model="
                                    entity.paciente.acesso_venoso_periferico
                                  "
                                />
                              </div>
                              <div class="col-md-3">
                                <label for="">Acesso Venoso Profundo</label>
                                <input
                                  type="number"
                                  placeholder="Local"
                                  class="form-control"
                                  v-model="
                                    entity.paciente.acesso_venoso_profundo
                                  "
                                />
                              </div>
                              <div class="col-md-3">
                                <label for="">Bomba Infusora</label>
                                <input
                                  type="number"
                                  placeholder="Quantidade"
                                  class="form-control"
                                  v-model="entity.paciente.bomba_infusora"
                                />
                              </div>
                            </div> -->
                            <!-- <div class="row mt-2">
                              <div class="col-md-3">
                                <label for="">Escala de Glasgow</label>
                                <input
                                  data-toggle="modal"
                                  data-target="#glasgowModalCenter"
                                  readonly
                                  type="text"
                                  class="form-control"
                                  id=""
                                  v-model="glasgowCount"
                                />
                              </div>
                              <div class="col-md-9" style="margin-top: 30px">
                                <span v-html="glasgowLabel"></span>
                              </div>
                            </div> -->
                          </div>
                        </div>

                        <!--<div class="row">
                          <div class="col-md-2">
                            <label>Peso</label>
                            <input
                              v-model="entity.paciente.peso"
                              type="text"
                              class="form-control"
                              v-mask="'999'"
                            />
                          </div>
                          <div class="col-md-3">
                            <label>Tipo de Peso</label>
                            <select
                              class="form-control"
                              v-model="entity.paciente.tipo_peso"
                            >
                              <option value="KG">Quilogramas</option>
                              <option value="G">Gramas</option>
                            </select>
                          </div>
                          <div class="col-md-2">
                            <label>Altura</label>
                            <input
                              v-model="entity.paciente.altura"
                              type="text"
                              class="form-control"
                              v-mask="'9.99'"
                            />
                          </div>
                        </div>

                        -->
                        <div class="row mt-2">
                          <div class="col-md-6">
                            <div
                              class="row"
                              v-for="(tac, index) in tipos_avaliacao_clinica"
                              :key="tac.tipo_avaliacao_clinica_id"
                              style="font-size: 14px"
                            >
                              <div class="col-md-12">
                                <div class="form-check">
                                  <input
                                    v-model="
                                      tipos_avaliacao_clinica[index].value
                                    "
                                    class="form-check-input"
                                    type="checkbox"
                                    value="true"
                                    :id="tac.tipo_avaliacao_clinica_id"
                                  />
                                  <label
                                    class="form-check-label"
                                    :for="tac.tipo_avaliacao_clinica_id"
                                    style="font-size: 16px"
                                  >
                                    {{ tac.tipo_avaliacao_clinica_nome }}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mt-1">
                            <label
                              ><span class="required">*</span> Paciente depende
                              de auxilio para se locomover?</label
                            >
                            <select
                              class="form-control"
                              v-model="entity.depende_auxilio"
                            >
                              <option value="SIM">Sim</option>
                              <option value="NÃO">Não</option>
                            </select>
                          </div>
                          <div
                            class="col-md-3 mt-1"
                            v-if="entity.depende_auxilio == 'SIM'"
                          >
                            <label><span class="required">*</span> Qual?</label>
                            <select
                              class="form-control"
                              v-model="entity.tipo_auxilio_locomocao_id"
                            >
                              <option
                                v-for="tipo in tipos_auxilio_locomocao"
                                :key="tipo.tipo_auxilio_locomocao_id"
                                :value="tipo.tipo_auxilio_locomocao_id"
                              >
                                {{ tipo.tipo_auxilio_locomocao_nome }}
                              </option>
                            </select>
                          </div>
                          <div
                            class="col-md-3 mt-1"
                            v-if="entity.tipo_auxilio_locomocao_id == '6'"
                          >
                            <label
                              ><span class="required">*</span>
                              Especifique</label
                            >

                            <input
                              type="text"
                              class="form-control"
                              v-model="entity.outro_tipo_auxilio_locomocao"
                            />
                          </div>
                        </div>
                        <div
                          class="row mt-2"
                          v-if="configuracoes.sinais_vitais == 1"
                        >
                          <div class="col-md-6">
                            <label>Precauções</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="entity.paciente.precaucao"
                            />
                          </div>
                        </div>

                        <div
                          class="row mt-3"
                          v-if="
                            $store.state.user.perfil_id == 2 ||
                            $store.state.user.perfil_id == 6 ||
                            $store.state.user.perfil_id == 21
                          "
                        >
                          <div class="col-md-12">
                            <h3>Antecedentes</h3>
                          </div>

                          <div class="col-md-3 mt-1">
                            <toggle-button
                              v-model="entity.paciente.hipertensao"
                              color="#15488A"
                              :height="25"
                            />
                            Hipertensão
                          </div>

                          <div class="col-md-3 mt-1">
                            <toggle-button
                              v-model="entity.paciente.avc"
                              color="#15488A"
                              :height="25"
                            />
                            AVC
                          </div>

                          <div class="col-md-3 mt-1">
                            <toggle-button
                              v-model="entity.paciente.etilismo"
                              color="#15488A"
                              :height="25"
                            />
                            Etilismo
                          </div>

                          <div class="col-md-3 mt-1">
                            <toggle-button
                              v-model="entity.paciente.iam"
                              color="#15488A"
                              :height="25"
                            />
                            IAM
                          </div>
                          <div class="col-md-3 mt-1">
                            <toggle-button
                              v-model="entity.paciente.epilepsia"
                              color="#15488A"
                              :height="25"
                            />
                            Epilepsia
                          </div>
                          <div class="col-md-3 mt-1">
                            <toggle-button
                              v-model="entity.paciente.diabetes"
                              color="#15488A"
                              :height="25"
                            />
                            Diabetes
                          </div>

                          <div class="col-md-3 mt-1">
                            <toggle-button
                              v-model="entity.paciente.asma"
                              color="#15488A"
                              :height="25"
                            />
                            Asma
                          </div>

                          <div class="col-md-6 mt-1">
                            <label for="outros">Outros: </label>
                            <input
                              type="text"
                              class="form-control"
                              id="outros"
                              v-model="entity.paciente.outros_antecedentes"
                            />
                          </div>
                        </div>

                        <div
                          class="row mb-2 mt-2"
                          v-if="configuracoes.cid_antropometria == 1"
                        >
                          <div class="col-md-12">
                            <div class="b-card-subtitle">CID's</div>
                          </div>
                        </div>
                        <div
                          class="row mt-1"
                          v-if="configuracoes.cid_antropometria == 1"
                        >
                          <div class="col-md-12">
                            <v-select
                              class="form-select"
                              v-model="cid_selecionado"
                              @search="searchCid"
                              :filterable="false"
                              label="cid_nome"
                              :options="cids"
                            >
                              <template
                                #no-options="{ search, searching, loading }"
                              >
                                Digite para pesquisar CID's
                              </template>

                              <template #option="{ cid_codigo, cid_nome }">
                                <div class="d-center">
                                  {{ cid_codigo }} - {{ cid_nome }}
                                </div>
                              </template>
                              <template
                                #selected-option="{ cid_codigo, cid_nome }"
                              >
                                <div class="selected d-center">
                                  {{ cid_codigo }} - {{ cid_nome }}
                                </div>
                              </template>
                            </v-select>
                          </div>

                          <div
                            class="col-md-12 mt-1 text-right"
                            v-if="configuracoes.cid_antropometria == 1"
                          >
                            <button class="btn btn-primary" @click="incluirCid">
                              <i class="fas fa-plus"></i> Incluir CID
                            </button>
                          </div>
                        </div>

                        <div
                          class="row mt-4"
                          v-if="
                            entity.cids.length > 0 &&
                            configuracoes.cid_avaliacao == 0
                          "
                        >
                          <div class="col-md-12">
                            <table
                              class="table table-bordered"
                              style="width: 100%"
                            >
                              <thead>
                                <tr>
                                  <th scope="col" style="width: 100%">
                                    Código e Nome
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="cid in entity.cids"
                                  :key="cid.cid_id"
                                >
                                  <td>
                                    {{ cid.cid_codigo }} - {{ cid.cid_nome }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div
                          class="row mb-2 mt-2"
                          v-if="
                            entity.cids.length > 0 &&
                            configuracoes.cid_avaliacao == 0
                          "
                        >
                          <div class="col-md-12">
                            <div class="b-card-subtitle">
                              Outras Observações
                            </div>
                          </div>
                          <div class="col-md-12">
                            <textarea
                              v-model="entity.paciente.outras_observacoes"
                              class="form-control"
                              id=""
                              cols="20"
                              rows="3"
                            ></textarea>
                          </div>
                        </div>
                      </section>
                      <section v-show="page == 4" v-if="estilo_transporte == 0">
                        <div
                          class="row mb-2 mt-2"
                          v-if="configuracoes.cid_avaliacao == 1"
                        >
                          <div class="col-md-12">
                            <div class="b-card-subtitle">CID's</div>
                          </div>
                        </div>
                        <div
                          class="row mt-1"
                          v-if="configuracoes.cid_avaliacao == 1"
                        >
                          <div class="col-md-12">
                            <v-select
                              class="form-select"
                              v-model="cid_selecionado"
                              @search="searchCid"
                              :filterable="false"
                              label="cid_nome"
                              :options="cids"
                            >
                              <template
                                #no-options="{ search, searching, loading }"
                              >
                                Digite para pesquisar CID's
                              </template>

                              <template #option="{ cid_codigo, cid_nome }">
                                <div class="d-center">
                                  {{ cid_codigo }} - {{ cid_nome }}
                                </div>
                              </template>
                              <template
                                #selected-option="{ cid_codigo, cid_nome }"
                              >
                                <div class="selected d-center">
                                  {{ cid_codigo }} - {{ cid_nome }}
                                </div>
                              </template>
                            </v-select>
                          </div>

                          <div
                            class="col-md-12 mt-1 text-right"
                            v-if="configuracoes.cid_avaliacao == 1"
                          >
                            <button class="btn btn-primary" @click="incluirCid">
                              <i class="fas fa-plus"></i> Incluir CID
                            </button>
                          </div>
                        </div>

                        <div
                          class="row mt-4"
                          v-if="
                            entity.cids.length > 0 &&
                            configuracoes.cid_avaliacao == 1
                          "
                        >
                          <div class="col-md-12">
                            <table
                              class="table table-bordered"
                              style="width: 100%"
                            >
                              <thead>
                                <tr>
                                  <th scope="col" style="width: 100%">
                                    Código e Nome
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="cid in entity.cids"
                                  :key="cid.cid_id"
                                >
                                  <td>
                                    {{ cid.cid_codigo }} - {{ cid.cid_nome }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div
                          class="row mb-2 mt-2"
                          v-if="configuracoes.cid_avaliacao == 1"
                        >
                          <div class="col-md-12">
                            <div class="b-card-subtitle">
                              Outras Observações
                            </div>
                          </div>
                          <div class="col-md-12">
                            <textarea
                              v-model="entity.paciente.outras_observacoes"
                              class="form-control"
                              id=""
                              cols="20"
                              rows="3"
                            ></textarea>
                          </div>
                        </div>

                        <section v-show="configuracoes.cid_avaliacao == 0">
                          <div class="row mb-2">
                            <div class="col-md-12">
                              <div class="b-card-subtitle">
                                Avaliações Clínicas do Paciente
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-12">
                              <textarea
                                v-model="avaliacao_clinica"
                                class="form-control"
                                id=""
                                cols="20"
                                rows="3"
                              ></textarea>
                            </div>
                            <div class="col-md-12 mt-1 text-right">
                              <button
                                class="btn btn-primary"
                                @click="incluirAvaliacao"
                              >
                                <i class="fas fa-plus"></i> Incluir Avaliação
                              </button>
                            </div>
                          </div>
                          <div
                            class="row mt-4"
                            v-if="entity.avaliacoes_clinicas.length > 0"
                          >
                            <div class="col-md-12">
                              <table
                                class="table table-bordered"
                                style="width: 100%"
                              >
                                <thead>
                                  <tr>
                                    <th scope="col" style="width: 52%">
                                      Avaliação
                                    </th>
                                    <th scope="col" style="width: 20%">
                                      Usuário
                                    </th>
                                    <th scope="col" style="width: 23%">
                                      Horário
                                    </th>
                                    <th
                                      v-if="
                                        configuracoes.deletar_avaliacao == 0
                                      "
                                      scope="col"
                                      style="width: 5%"
                                    >
                                      Ações
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(
                                      avaliacao, index
                                    ) in entity.avaliacoes_clinicas"
                                    :key="
                                      moment(avaliacao.datetime).format(
                                        'HHmmss'
                                      )
                                    "
                                  >
                                    <td>{{ avaliacao.avaliacao }}</td>
                                    <td>
                                      {{ avaliacao.usuario.usuario_nome }}
                                    </td>
                                    <td>
                                      {{
                                        moment(avaliacao.datetime).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )
                                      }}
                                    </td>
                                    <td
                                      v-if="
                                        configuracoes.deletar_avaliacao == 0
                                      "
                                      class="centered clickable"
                                      @click="removerAvaliacao(index)"
                                    >
                                      <i class="fas fa-trash-alt"></i>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </section>
                      </section>
                      <section v-if="page == 5 && estilo_transporte == 1">
                        <div class="row mb-2">
                          <div class="col-md-12">
                            <div class="b-card-subtitle">
                              Selecione os Materiais
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <label
                              >Selecione um material para inserir ao
                              transporte</label
                            >
                            <vue-simple-suggest
                              v-model="material_selecionado"
                              :list="produtos"
                              display-attribute="produto_nome"
                              value-attribute="produto_nome"
                              :filter-by-query="true"
                            >
                            </vue-simple-suggest>
                          </div>
                          <div class="col-md-12 mt-1 text-right">
                            <button
                              class="btn btn-primary"
                              @click="incluirMaterial"
                            >
                              <i class="fas fa-plus"></i> Incluir Material
                            </button>
                          </div>
                        </div>
                        <div class="row mt-4">
                          <div class="col-md-12">
                            <table
                              class="table table-bordered"
                              style="width: 100%"
                            >
                              <thead>
                                <tr>
                                  <th scope="col" style="width: 100%">Nome</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="material in entity.material.materiais"
                                  :key="material"
                                >
                                  <td>
                                    {{ material.produto_nome }}
                                  </td>
                                </tr>
                                <tr
                                  v-if="entity.material.materiais.length == 0"
                                  align="center"
                                >
                                  <td>Nenhum material vinculado.</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-12">
                            <div class="b-card-subtitle">
                              Cuidados com o Transporte
                            </div>
                          </div>
                        </div>

                        <div class="row mt-2">
                          <div class="col-md-6">
                            <div
                              class="row"
                              v-for="(tc, index) in tipos_cuidado"
                              :key="tc.tipo_cuidado_id"
                              style="font-size: 14px"
                            >
                              <div class="col-md-12">
                                <div class="form-check">
                                  <input
                                    v-model="tipos_cuidado[index].value"
                                    class="form-check-input"
                                    type="checkbox"
                                    value="true"
                                    :id="tc.tipo_cuidado_id"
                                  />
                                  <label
                                    class="form-check-label"
                                    :for="tc.tipo_cuidado_id"
                                    style="font-size: 16px"
                                  >
                                    {{ tc.tipo_cuidado_nome }}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section v-show="page == 6">
                        <div class="row mb-2">
                          <div class="col-md-12">
                            <div class="b-card-subtitle">Agendamento</div>
                          </div>
                        </div>
                        <div class="row" v-if="configuracoes.recorrencia == 1">
                          <div class="col-md-3">
                            <toggle-button
                              v-model="entity.flag_recorrencia"
                              color="#15488A"
                              :height="25"
                            />
                            É uma recorrência?
                            <!-- <div class="custom-control custom-switch">
                              <input
                                autocomplete="new-password"
                                v-model="entity.flag_recorrencia"
                                placeholder=""
                                type="checkbox"
                                class="custom-control-input"
                                id="customSwitch1"
                              />
                              <label
                                class="custom-control-label"
                                for="customSwitch1"
                                >É uma recorrência?</label
                              >
                            </div> -->
                          </div>
                        </div>

                        <div class="row mt-2" v-if="!entity.flag_recorrencia">
                          <div class="col-md-3">
                            <label>Data/Hora do Transporte</label>
                            <input
                              type="datetime-local"
                              class="form-control"
                              @input="limitarAno"
                              v-model="entity.data_procedimento"
                              step="1"
                            />
                          </div>
                        </div>
                        <div class="row mt-2" v-if="entity.flag_recorrencia">
                          <div class="col-md-3">
                            <label>Tipo de Recorrência</label>
                            <select
                              class="form-control"
                              v-model="entity.tipo_recorrencia"
                            >
                              <option value="1">Semanal</option>
                              <option value="2">Mensal</option>
                              <option value="3">Diário</option>
                            </select>
                          </div>
                        </div>
                        <div
                          class="row mt-2"
                          v-if="entity.tipo_recorrencia == 1"
                        >
                          <div class="col-md-12">
                            <label>Selecione os dias da semana</label>

                            <v-select
                              :options="dias_semana"
                              :label="'semana_nome'"
                              v-model="entity.dias_semana"
                              multiple
                              class="vselect"
                            />
                          </div>
                        </div>
                        <div class="row mt-2" v-if="entity.flag_recorrencia">
                          <div class="col-md-3">
                            <label>Data do primeiro transporte</label>
                            <input
                              type="date"
                              class="form-control"
                              v-model="entity.data_procedimento"
                              @input="limitarAno"
                            />
                          </div>
                          <div class="col-md-3">
                            <label>Data do último transporte</label>
                            <input
                              type="date"
                              class="form-control"
                              v-model="entity.data_procedimento_vigencia"
                              @input="limitarAnoUltimaRecorrencia"
                            />
                          </div>
                        </div>

                        <div class="row mt-2" v-if="entity.flag_recorrencia">
                          <div class="col-md-2">
                            <label>Horário do Transporte</label>
                            <input
                              type="time"
                              class="form-control"
                              v-model="horario_transporte"
                            />
                          </div>
                          <div class="col-md-3" style="margin-top: 25px">
                            <button class="btn btn-back" @click="addHorario">
                              Adicionar Horário
                            </button>
                          </div>
                        </div>
                        <div class="row mt-2" v-if="entity.flag_recorrencia">
                          <div class="col-md-6">
                            <label>Horários Adicionados</label>
                            <v-select
                              v-model="entity.horarios"
                              multiple
                              class="vselect"
                            />
                          </div>
                        </div>

                        <div
                          class="row mt-2"
                          v-if="
                            configuracoes.classificacao == 1 &&
                            ($store.state.user.perfil_id == 6 ||
                              $store.state.user.perfil_id == 2 ||
                              $store.state.user.perfil_id == 21)
                          "
                        >
                          <div class="col-md-3">
                            <label
                              ><span class="required">*</span> Classificação da
                              ocorrência</label
                            >

                            <v-select
                              :options="classificacoes"
                              label="descricao"
                              v-model="entity.classificacao_id"
                            ></v-select>
                          </div>
                          <div
                            v-if="entity.classificacao_id"
                            class="mt-2 classificacao"
                            :style="
                              'background: ' + entity.classificacao_id.cor
                            "
                          ></div>
                          <!-- <select class="form-control" v-model="entity.classificacao_id"
                              :style="'background: ' + entity.classificacao_id.cor + ';' + 'font-weight: bold; font-size: larger; color: white;'">
                              <option v-for="classificacao in classificacoes" :key="classificacao.classificacao_id"
                                :value="classificacao" :style="'background: ' + classificacao.cor + ' !important;'">
                                {{ classificacao.descricao }}
                              </option> -->
                          <!-- </select> -->
                        </div>
                      </section>
                      <div class="row">
                        <div class="col-md-12 mt-4 block text-right">
                          <div class="col-md-12">
                            <div class="block text-right">
                              <button
                                class="btn btn-back mr-1"
                                @click="lastPage"
                                v-if="
                                  page > initial_page || (inverse && page == 1)
                                "
                              >
                                <i class="fa fa-arrow-left"></i> Etapa Anterior
                              </button>
                              <button
                                class="btn btn-add"
                                v-if="page < finish_page"
                                @click="nextPage"
                              >
                                Próxima Etapa
                                <i class="fa fa-arrow-right"></i>
                              </button>
                              <button
                                class="btn btn-add"
                                v-if="page == finish_page"
                                @click="save"
                              >
                                Salvar Solicitação
                                <i class="fa fa-save"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      class="btn btn-approve"
      @click="back"
      v-if="exibir_voltar_aprovacao"
    >
      Salvar e Voltar para Aprovação
    </button>

    <!-- Modal Glasgow -->
    <div
      class="modal fade"
      id="glasgowModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="glasgowModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="glasgowModalLongTitle">
              Escala de Glasgow
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" v-if="entity.paciente">
            <div class="row">
              <div class="col-md-12">
                <table class="table table-striped" style="width: 100%">
                  <thead>
                    <tr>
                      <th scope="col" colspan="3">Abertura Ocular</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row" style="width: 60%">Espontânea</th>
                      <td style="width: 20%">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            id="espontanea1"
                            v-model="entity.paciente.espontanea"
                            value="4"
                          />
                          <label class="form-check-label" for="espontanea1">
                          </label>
                        </div>
                      </td>
                      <td style="width: 20%">4</td>
                    </tr>
                    <tr>
                      <th scope="row">À voz</th>
                      <td style="width: 20%">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            v-model="entity.paciente.espontanea"
                            value="3"
                            id="espontanea2"
                          />
                          <label class="form-check-label" for="espontanea2">
                          </label>
                        </div>
                      </td>
                      <td style="width: 20%">3</td>
                    </tr>
                    <tr>
                      <th scope="row">À dor</th>
                      <td style="width: 20%">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            v-model="entity.paciente.espontanea"
                            value="2"
                            id="espontanea3"
                          />
                          <label class="form-check-label" for="espontanea3">
                          </label>
                        </div>
                      </td>
                      <td style="width: 20%">2</td>
                    </tr>
                    <tr>
                      <th scope="row">Nenhuma</th>
                      <td style="width: 20%">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            v-model="entity.paciente.espontanea"
                            value="1"
                            id="espontanea4"
                          />
                          <label class="form-check-label" for="espontanea4">
                          </label>
                        </div>
                      </td>
                      <td style="width: 20%">1</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-md-12">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col" colspan="3">Resposta verbal</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row" style="width: 60%">Orientada</th>
                      <td style="width: 20%">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            v-model="entity.paciente.verbal"
                            id="verbal1"
                            value="5"
                          />
                          <label class="form-check-label" for="verbal1">
                          </label>
                        </div>
                      </td>
                      <td style="width: 20%">5</td>
                    </tr>
                    <tr>
                      <th scope="row" style="width: 60%">Confusa</th>
                      <td style="width: 20%">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            v-model="entity.paciente.verbal"
                            id="verbal2"
                            value="4"
                          />
                          <label class="form-check-label" for="verbal2">
                          </label>
                        </div>
                      </td>
                      <td style="width: 20%">4</td>
                    </tr>
                    <tr>
                      <th scope="row" style="width: 60%">
                        Palavras inapropriadas
                      </th>
                      <td style="width: 20%">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            v-model="entity.paciente.verbal"
                            id="verbal3"
                            value="3"
                          />
                          <label class="form-check-label" for="verbal3">
                          </label>
                        </div>
                      </td>
                      <td style="width: 20%">3</td>
                    </tr>
                    <tr>
                      <th scope="row" style="width: 60%">
                        Palavras incompreensivas
                      </th>
                      <td style="width: 20%">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            v-model="entity.paciente.verbal"
                            id="verbal4"
                            value="2"
                          />
                          <label class="form-check-label" for="verbal4">
                          </label>
                        </div>
                      </td>
                      <td style="width: 20%">2</td>
                    </tr>
                    <tr>
                      <th scope="row" style="width: 60%">Nenhuma</th>
                      <td style="width: 20%">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            v-model="entity.paciente.verbal"
                            id="verbal5"
                            value="1"
                          />
                          <label class="form-check-label" for="verbal5">
                          </label>
                        </div>
                      </td>
                      <td style="width: 20%">1</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-md-12">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col" colspan="3">Resposta motora</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row" style="width: 60%">Obedece comandos</th>
                      <td style="width: 20%">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            v-model="entity.paciente.motora"
                            id="motora1"
                            value="6"
                          />
                          <label class="form-check-label" for="motora1">
                          </label>
                        </div>
                      </td>
                      <td style="width: 20%">6</td>
                    </tr>
                    <tr>
                      <th scope="row" style="width: 60%">Localiza dor</th>
                      <td style="width: 20%">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            v-model="entity.paciente.motora"
                            id="motora2"
                            value="5"
                          />
                          <label class="form-check-label" for="motora2">
                          </label>
                        </div>
                      </td>
                      <td style="width: 20%">5</td>
                    </tr>
                    <tr>
                      <th scope="row" style="width: 60%">
                        Movimento de retirada
                      </th>
                      <td style="width: 20%">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            v-model="entity.paciente.motora"
                            id="motora3"
                            value="4"
                          />
                          <label class="form-check-label" for="motora3">
                          </label>
                        </div>
                      </td>
                      <td style="width: 20%">4</td>
                    </tr>
                    <tr>
                      <th scope="row" style="width: 60%">Flexão anormal</th>
                      <td style="width: 20%">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            v-model="entity.paciente.motora"
                            id="motora4"
                            value="3"
                          />
                          <label class="form-check-label" for="motora4">
                          </label>
                        </div>
                      </td>
                      <td style="width: 20%">3</td>
                    </tr>
                    <tr>
                      <th scope="row" style="width: 60%">Extensão anormal</th>
                      <td style="width: 20%">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            v-model="entity.paciente.motora"
                            id="motora5"
                            value="2"
                          />
                          <label class="form-check-label" for="motora5">
                          </label>
                        </div>
                      </td>
                      <td style="width: 20%">2</td>
                    </tr>
                    <tr>
                      <th scope="row" style="width: 60%">Nenhuma</th>
                      <td style="width: 20%">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            v-model="entity.paciente.motora"
                            id="motora6"
                            value="1"
                          />
                          <label class="form-check-label" for="motora6">
                          </label>
                        </div>
                      </td>
                      <td style="width: 20%">1</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Fechar
            </button>
            <button type="button" class="btn btn-primary" data-dismiss="modal">
              Salvar Pontuação
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import vSelect from "vue-select";
import VueSimpleSuggest from "vue-simple-suggest";
import "vue-simple-suggest/dist/styles.css";
import moment from "moment";

export default {
  name: "imageUpload",
  data() {
    return {
      tipos_unidade_saude: [],
      origem_outros: false,
      destino_outros: false,
      valorMaximo: 300,
      valorApgar: 10,
      valorIdadeGestacional: 45,
      valorMaximoTempetura: 60,
      valorMaximoSaturacao: 100,
      valorMaximoGlicemia: 1000,
      inverse: false,
      navbar_loaded: true,
      estilo_transporte: 9,
      conselho_classe: [
        {
          nome: "CRM",
          id: "1",
        },
        {
          nome: "CRF",
          id: "2",
        },
        {
          nome: "COREN",
          id: "3",
        },
        {
          nome: "CRO",
          id: "4",
        },
        {
          nome: "CRP",
          id: "5",
        },
        {
          nome: "RG",
          id: "7",
        },
        {
          nome: "Ass Social",
          id: "8",
        },
        {
          nome: "Não informado",
          id: "6",
        },
      ],
      dias_semana: [
        {
          semana_nome: "Segunda-feira",
          id: "1",
        },
        {
          semana_nome: "Terça-feira",
          id: "2",
        },
        {
          semana_nome: "Quarta-feira",
          id: "3",
        },
        {
          semana_nome: "Quinta-feira",
          id: "4",
        },
        {
          semana_nome: "Sexta-feira",
          id: "5",
        },
        {
          semana_nome: "Sábado",
          id: "6",
        },
        {
          semana_nome: "Domingo",
          id: "0",
        },
      ],
      exibir_voltar_aprovacao: false,
      moment: moment,
      isLoading: true,
      page: 1,
      finish_page: 6,
      initial_page: 1,
      data_procedimento_original: "",
      tipos_transporte: [],
      tipos_domicilio: [],
      tipos_avaliacao_clinica: [],
      tipos_cuidado: [],
      produtos: [],
      tipos_auxilio_locomocao: [],
      tipos_acompanhante: [],
      motivo_transporte: [],
      unidade_saude: [],
      cbos: [],
      ufs: [],
      classificacoes: [],
      municipios: [],
      entity: {
        destino_outras_unidades: false,
        destino_outros: false,
        flag_recorrencia: false,
        tipo_destino: "Municipal",
        origem: {
          endereco: "",
          bairro: "",
          numero: "",
          cep: "",
          complemento: "",
          municipio_id: "",
          estado_id: "",
        },
        material: {
          materiais: [],
        },
        destino: {},
        data_solicitacao: "",
        paciente: {
          tipo_peso: "KG",
          dificuldade_acesso_domicilio: "NÃO",
          dificuldade_acesso_paciente: "NÃO",
          saturacao_tipo: "Ar Atmosférico",
          glicemia_tipo: "Pós-prandial",
          flg_bomba_infusora: false,
          espontanea: "",
          verbal: "",
          motora: "",
        },
        classificacao_id: {
          classificacao_id: "",
        },
        possui_acompanhante: "NÃO",
        acompanhante: {},
        solicitante: {},
        avaliacoes_clinicas: [],
        cids: [],
        horarios: [],
        horario_transporte: "",
        unidade: {},
      },
      avaliacao_clinica: "",
      cid_selecionado: {},
      endereco_paciente: {},
      material_selecionado: "",
      cids: [],
      ufs: [],
      municipios_origem: [],
      municipios_destino: [],
      suggestionAttribute: "original_title",
      suggestions: [{ original_title: "testeee" }],
      selectedEvent: "",
      prevRoute: null,
      glasgow: {},
      configuracoes: {
        judicial: "",
        sinais_vitais: "",
        recorrencia: 0,
        tipo_destino: 0,
        dificuldade_acesso: "",
        solicitacao_manual: 0,
      },
      idade_texto: "",
      pages_available: [1, 2, 3, 4, 5, 6],
      medicamentos: [
        "AMIODARONA (ANCORON) 150 MG",
        "DOPAMINA 5MG/ML 10ML-AMPOLA",
        "DOBUTAMINA 12,5MG/ML 20ML-AMPOLA",
        "NORAEPINEFRINA 2MG/4ML 4ML-AMPOLA",
        "FENTANILA 50 mcg/10ml - AMP.",
        "INSULINA REGULAR",
        "MIDAZOLAN 15MG/ML 10ML-AMP",
        "TRIDIL",
      ],
      data_aniversario: {
        texto_data: "Informação Inexistente",
        data_inexistente: false,
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  computed: {
    diffIdadeDias() {
      const self = this;

      var dataNascimento = self.entity.paciente.data_nascimento;
      var dataNascimentoObj = new Date(dataNascimento);
      var dataAtual = new Date();
      var diferencaMilissegundos = dataAtual - dataNascimentoObj;
      var diferencaDias = diferencaMilissegundos / (1000 * 60 * 60 * 24);

      return diferencaDias;
    },

    imc() {
      var imc =
        this.entity.paciente.peso /
        (this.entity.paciente.altura * this.entity.paciente.altura);
      return Math.round(imc);
    },
    glasgowCount() {
      var returnVal =
        Number(this.entity.paciente.verbal) +
        Number(this.entity.paciente.motora) +
        Number(this.entity.paciente.espontanea);

      if (isNaN(returnVal)) {
        console.log("aqui2", isNaN(returnVal));
        return "Não classificado";
      }
      console.log("aqui", returnVal);
      return Math.round(returnVal);
    },
    glasgowLabel() {
      var string = "";

      switch (this.entity.paciente.verbal) {
        case "5":
          string += "<strong>Resposta verbal:</strong> Orientada ";
          break;
        case "4":
          string += "<strong>Resposta verbal:</strong> Confusa ";
          break;
        case "3":
          string += "<strong>Resposta verbal:</strong> Palavras inapropriadas ";
          break;
        case "2":
          string +=
            "<strong>Resposta verbal:</strong> Palavras incompreensivas ";
          break;
        case "1":
          string += "<strong>Resposta verbal:</strong> Nenhuma ";
          break;
        default:
          string += "";
      }

      switch (this.entity.paciente.espontanea) {
        case "4":
          string += "<strong>Resposta ocular:</strong> Espontânea ";
          break;
        case "3":
          string += "<strong>Resposta ocular:</strong> À Voz ";
          break;
        case "2":
          string += "<strong>Resposta ocular:</strong> À Dor ";
          break;
        case "1":
          string += "<strong>Resposta ocular:</strong> Nenhuma ";
          break;
        default:
          string += "";
      }

      switch (this.entity.paciente.motora) {
        case "6":
          string += "<strong>Resposta motora:</strong> Obedece comandos ";
          break;
        case "5":
          string += "<strong>Resposta motora:</strong> Localiza dor ";
          break;
        case "4":
          string += "<strong>Resposta motora:</strong> Movimento de retirada ";
          break;
        case "3":
          string += "<strong>Resposta motora:</strong> Flexão anormal ";
          break;
        case "2":
          string += "<strong>Resposta motora:</strong> Extensão anormal ";
          break;
        case "1":
          string += "<strong>Resposta motora:</strong> Nenhuma ";
          break;
        default:
          string += "";
      }

      return string;
    },

    glasgowLabelTxt() {
      var string = "";

      switch (this.entity.paciente.verbal) {
        case "5":
          string += "Resposta verbal: Orientada ";
          break;
        case "4":
          string += "Resposta verbal: Confusa ";
          break;
        case "3":
          string += "Resposta verbal: Palavras inapropriadas ";
          break;
        case "2":
          string += "Resposta verbal: Palavras incompreensivas ";
          break;
        case "1":
          string += "Resposta verbal: Nenhuma ";
          break;
        default:
          string += "";
      }

      switch (this.entity.paciente.espontanea) {
        case "4":
          string += "Resposta ocular: Espontânea ";
          break;
        case "3":
          string += "Resposta ocular: À Voz ";
          break;
        case "2":
          string += "Resposta ocular: À Dor ";
          break;
        case "1":
          string += "Resposta ocular: Nenhuma ";
          break;
        default:
          string += "";
      }

      switch (this.entity.paciente.motora) {
        case "6":
          string += "Resposta motora: Obedece comandos ";
          break;
        case "5":
          string += "Resposta motora: Localiza dor ";
          break;
        case "4":
          string += "Resposta motora: Movimento de retirada ";
          break;
        case "3":
          string += "Resposta motora: Flexão anormal ";
          break;
        case "2":
          string += "Resposta motora: Extensão anormal ";
          break;
        case "1":
          string += "Resposta motora: Nenhuma ";
          break;
        default:
          string += "";
      }

      return string;
    },
  },
  watch: {
    "entity.paciente.data_nascimento": function () {
      this.diffIdade();
    },
    "entity.possui_acompanhante": function (newVal, oldVal) {
      if (newVal === "SIM") {
        this.initializeAcompanhante();
      }
    },
  },
  methods: {
    initializeAcompanhante() {
      if (
        this.entity.possui_acompanhante === "SIM" &&
        !this.entity.acompanhante
      ) {
        this.$set(this.entity, "acompanhante", {
          tipo_acompanhante_id: "",
          acompanhante_nome: "",
          acompanhante_cpf: "",
          acompanhante_telefone: "",
        });
      }
    },
    buscarUnidadeSolicitante: function () {
      var self = this;

      self.naoLocalizado = false;

      if (/^[0-9]{5}-[0-9]{3}$/.test(self.entity.unidade.cep)) {
        $.getJSON(
          "https://viacep.com.br/ws/" + self.entity.unidade.cep + "/json/",
          function (endereco) {
            if (endereco.erro) {
              self.endereco = {};
              $("#inputNumeroUnidade").focus();
              self.naoLocalizado = true;
              return;
            }

            self.entity.unidade.endereco = endereco.logradouro;
            self.entity.unidade.bairro = endereco.bairro;
            self.entity.unidade.complemento = endereco.complemento;

            let api =
              self.$store.state.api +
              "municipios?municipio_codigo=" +
              endereco.ibge.substring(0, endereco.ibge.length - 1);

            axios
              .get(api)
              .then((response) => {
                self.entity.unidade.estado_id = response.data.data[0].uf_id;
                self.getEstadoMunicipioUnidadeSolicitante();
                self.entity.unidade.municipio_id =
                  response.data.data[0].municipio_id;
              })
              .catch((error) => {
                self.$message(null, error.response.data, "error");
              });

            $("#inputNumeroUnidade").focus();
          }
        );
      }
    },
    getEstadoMunicipioUnidadeSolicitante() {
      const self = this;
      let api =
        self.$store.state.api +
        "municipios?orderByAsc=municipio_nome&paginated=false&uf_id=" +
        self.entity.unidade.estado_id;

      axios
        .get(api)
        .then((response) => {
          self.municipios = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    diffIdade() {
      const self = this;

      var a = moment();
      var b = moment(self.entity.paciente.data_nascimento);

      var years = a.diff(b, "year");
      b.add(years, "years");

      var months = a.diff(b, "months");
      b.add(months, "months");

      var days = a.diff(b, "days");

      if (years < 100) {
        this.idade_texto =
          years + " anos, " + months + " meses, " + days + " dias";
      }
    },

    limitarAno() {
      const partesData = this.entity.data_procedimento.split("-");
      let ano = partesData[0];
      let mes = partesData[1];
      let dia = partesData[2];

      if (ano.length > 4) {
        ano = ano.slice(0, 4);

        this.entity.data_procedimento =
          ano + "-" + partesData[1] + "-" + partesData[2];
      }

      // if (ano < '2023') {

      //   this.entity.data_procedimento = '2023' + '-' + partesData[1] + '-' + partesData[2];

      // }
    },

    limitarAnoUltimaRecorrencia() {
      const partesData = this.entity.data_procedimento_vigencia.split("-");
      let ano = partesData[0];

      if (ano.length > 4) {
        ano = ano.slice(0, 4);

        this.entity.data_procedimento_vigencia =
          ano + "-" + partesData[1] + "-" + partesData[2];
      }
    },

    limitarValor() {
      if (this.entity.paciente.pa_sistolica > this.valorMaximo) {
        this.entity.paciente.pa_sistolica = this.valorMaximo;
      }
      if (this.entity.paciente.pa_diastolica > this.valorMaximo) {
        this.entity.paciente.pa_diastolica = this.valorMaximo;
      }
      if (this.entity.paciente.temperatura > this.valorMaximoTempetura) {
        this.entity.paciente.temperatura = this.valorMaximoTempetura;
      }
      if (this.entity.paciente.fr > this.valorMaximo) {
        this.entity.paciente.fr = this.valorMaximo;
      }
      if (this.entity.paciente.fc > this.valorMaximo) {
        this.entity.paciente.fc = this.valorMaximo;
      }
      if (this.entity.paciente.saturacao > this.valorMaximoSaturacao) {
        this.entity.paciente.saturacao = this.valorMaximoSaturacao;
      }
      if (this.entity.paciente.glicemia > this.valorMaximoGlicemia) {
        this.entity.paciente.glicemia = this.valorMaximoGlicemia;
      }
      if (this.entity.paciente.idade_gestacional > this.valorIdadeGestacional) {
        this.entity.paciente.idade_gestacional = this.valorIdadeGestacional;
      }
      if (this.entity.paciente.apgar_1 > this.valorApgar) {
        this.entity.paciente.apgar_1 = this.valorApgar;
      }
      if (this.entity.paciente.apgar_5 > this.valorApgar) {
        this.entity.paciente.apgar_5 = this.valorApgar;
      }
    },
    addHorario() {
      const self = this;
      if (self.horario_transporte == "") {
        self.$message(null, "Você precisa informar um horário", "error");
        return;
      }
      self.entity.horarios.push(self.horario_transporte);
      self.horario_transporte = "";
    },
    getEnderecoPaciente() {
      const self = this;

      var cpf = self.entity.paciente.cpf.replace(/\.|\-/g, "");

      let api = self.$store.state.api + "paciente?cpf=" + cpf;

      self.$loading(true);
      axios
        .get(api)
        .then((response) => {
          self.endereco_paciente = response.data.data[0];

          if (self.endereco_paciente) {
            self.entity.origem.tipo_estabelecimento_id =
              self.endereco_paciente.tipo_domicilio_id;
            self.entity.origem.endereco = self.endereco_paciente.endereco;
            self.entity.origem.bairro = self.endereco_paciente.bairro;
            self.entity.origem.numero = self.endereco_paciente.numero;
            self.entity.origem.cep = self.endereco_paciente.cep;
            self.entity.origem.complemento = self.endereco_paciente.complemento;
            self.entity.origem.telefone = self.endereco_paciente.telefone;
            self.entity.origem.ponto_referencia =
              self.endereco_paciente.ponto_referencia;
            self.entity.origem.municipio_id =
              self.endereco_paciente.municipio_id;
            self.entity.origem.estado_id = self.endereco_paciente.estado_id;
            self.getEstadoMunicipioOrigem();
          } else {
            self.$message(
              null,
              "Não existe um paciente cadastrado com o CPF informado",
              "error"
            );
          }

          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    back() {
      this.save();
    },
    changeDataProcedimento() {
      const self = this;

      if (self.entity.id) {
        if (self.entity.data_procedimento != self.data_procedimento_original) {
          swal({
            title: "Atenção!",
            text: "Ao alterar a data do procedimento, esta solicitação deverá ser reprogramada, a programação atual será excluída e uma nova deverá ser feita.",
            type: "info",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Sim, alterar!",
            showLoaderOnConfirm: true,
            buttons: {
              catch: {
                text: "OK, não alterar",
                value: "cancel",
                className: "btn-danger",
              },
              confirm: {
                text: "Sim, alterar a data",
                value: "confirm",
              },
            },
          }).then((value) => {
            switch (value) {
              case "cancel":
                self.entity.data_procedimento = self.data_procedimento_original;
                break;

              case "confirm":
                break;
            }
          });
        }
      }
    },
    save() {
      const self = this;
      let api = self.$store.state.api + "solicitacoes";

      self.$loading(true);
      self.entity.condicoes_clinicas = self.tipos_avaliacao_clinica;
      self.entity.cuidados_transporte = self.tipos_cuidado;

      if (self.estilo_transporte == 0) {
        if (Array.isArray(self.entity.paciente.bomba_infusora_medicamentos)) {
          var string = "";
          var i = 1;
          self.entity.paciente.bomba_infusora_medicamentos.forEach(
            (element) => {
              string = string + element;

              if (i < self.entity.paciente.bomba_infusora_medicamentos.length) {
                string = string + ", ";
              }
              i++;
            }
          );

          self.entity.paciente.bomba_infusora_medicamentos = string;
        }
      }

      self.entity.estilo_transporte = self.estilo_transporte;

      if (self.entity.transporte_sanitario_id) {
        if (self.$store.state.user.perfil_id != 5) {
          if (self.entity.classificacao_id == null) {
            self.$message(
              "Ops!",
              "Você precisa selecionar uma classificação para esta solicitação",
              "error"
            );

            self.$loading(false);

            return;
          }
        }

        self.entity._method = "PUT";
        api = api + "/" + self.entity.transporte_sanitario_id;
      }

      axios
        .post(api, self.entity)
        .then((response) => {
          self.$message(
            "Tudo certo!",
            "Solicitação enviada com sucesso.",
            "success"
          );

          self.$loading(false);

          self.$router.push("/solicitacoes");

          if (self.prevRoute.path.split("/")[1] == "aprovacoes") {
            self.$router.push(self.prevRoute.path);
          } else {
            self.$router.push("/solicitacoes");
          }
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
    incluirAvaliacao() {
      const self = this;

      if (self.avaliacao_clinica == "") {
        self.$message(
          null,
          "Avaliação clínica do paciente, não pode ser vazia",
          "error"
        );
        return;
      }

      const today = moment().format("YYYY-MM-DD HH:mm:ss");

      var obj = {
        datetime: today,
        usuario: self.$store.state.user,
        avaliacao: self.avaliacao_clinica,
      };

      self.entity.avaliacoes_clinicas.push(obj);

      self.avaliacao_clinica = "";
    },
    removerAvaliacao(index) {
      const self = this;
      self.entity.avaliacoes_clinicas.splice(index, 1);
    },
    incluirCid() {
      const self = this;

      if (!self.cid_selecionado.cid_nome) {
        self.$message(null, "Você precisa escolher um CID", "error");
        return;
      }

      self.entity.cids.push(self.cid_selecionado);

      self.cid_selecionado = {};
    },
    incluirMaterial() {
      const self = this;

      if (!self.material_selecionado) {
        self.$message(null, "Você precisa escolher um Material", "error");
        return;
      }

      self.entity.material.materiais.push({
        produto_nome: self.material_selecionado,
      });

      self.material_selecionado = "";
    },
    verifyAvaliacao() {
      const self = this;
      if (self.avaliacao_clinica != "") {
        self.incluirAvaliacao();
      }
    },
    lastPage() {
      const self = this;

      if (self.avaliacao_clinica != "") {
        self.incluirAvaliacao();
      }

      var index = self.pages_available.indexOf(self.page);

      var next_page = self.pages_available[index - 1];

      self.page = next_page;
    },
    nextPage() {
      const self = this;

      if (self.avaliacao_clinica != "") {
        self.incluirAvaliacao();
      }

      // if (!self.entity.tipo_transporte_id) {
      //   self.$message(
      //     null,
      //     "Selecione um tipo de transporte para prosseguir.",
      //     "error"
      //   );
      //   return;
      // }

      var index = self.pages_available.indexOf(self.page);

      var next_page = self.pages_available[index + 1];

      self.page = next_page;

      if (self.page == 3) {
        self.getTiposAvaliacaoClinica();
        self.getTiposAuxilioLocomocao();
        return;
      }
    },
    searchCid(search, loading) {
      if (search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: _.debounce((loading, search, vm) => {
      const api = vm.$store.state.api + "cids?limit=15&cid_nome=" + search;

      axios
        .get(api)
        .then((response) => {
          vm.cids = response.data.data;
        })
        .then(() => {
          loading(false);
        })
        .catch((error) => {
          vm.$toast.error(error.response.data.message);
        });
    }, 700),
    getTiposTransporte: function () {
      const self = this;
      let api =
        self.$store.state.api + "tipo_transportes?paginated=false&status=1";

      axios
        .get(api)
        .then((response) => {
          self.tipos_transporte = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getTiposDomicilio: function () {
      const self = this;
      let api = self.$store.state.api + "tipo_domicilio?status=1";

      axios
        .get(api)
        .then((response) => {
          self.tipos_domicilio = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getConfiguracoes: function () {
      const self = this;
      let api = self.$store.state.api + "configuracoes?paginated=false";

      self.$loading(true);
      axios
        .get(api)
        .then((response) => {
          self.configuracoes = response.data.data[0];
          self.setPages();
          self.getClassificacoes();
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getClassificacoes: function () {
      const self = this;
      let api = self.$store.state.api + "classificacoes?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.classificacoes = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getTiposCuidado: function () {
      const self = this;
      let api = self.$store.state.api + "tipo_cuidado";

      if (self.tipos_cuidado.length != 0) {
        return;
      }

      self.$loading(true);

      axios
        .get(api)
        .then((response) => {
          self.tipos_cuidado = response.data.data;
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
    getProdutos: function () {
      const self = this;
      let api = self.$store.state.api + "produtos";

      axios
        .get(api)
        .then((response) => {
          self.produtos = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getMotivos() {
      const self = this;
      let api = self.$store.state.api + "get_motivos_por_tipo";

      self.tipos_transporte.forEach((element) => {
        if (element.tipo_transporte_id == self.entity.tipo_transporte_id) {
          self.estilo_transporte = element.tipo_solicitacao;
          self.setPages();
        }
      });

      axios
        .post(api, { tipo_transporte: self.entity.tipo_transporte_id })
        .then((response) => {
          self.motivo_transporte = response.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getEstadoMunicipio() {
      const self = this;
      let api =
        self.$store.state.api +
        "municipios?orderByAsc=municipio_nome&paginated=false&uf_id=" +
        self.entity.paciente.estado_id;

      axios
        .get(api)
        .then((response) => {
          self.municipios = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getUnidades: function (id) {
      const self = this;
      let api =
        self.$store.state.api +
        "unidade_saude?paginated=false&orderByAsc=unidade_saude_nome";

      self.$loading(true);

      axios
        .get(api)
        .then((response) => {
          self.unidade_saude = response.data.data;
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
    getTiposUnidadesSaude: function () {
      const self = this;
      let api =
        self.$store.state.api + "tipo_unidade_saude?paginated=false&status=1";

      axios
        .get(api)
        .then((response) => {
          self.tipos_unidade_saude = response.data.data;
          console.log(response.data.data);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getTiposAvaliacaoClinica: function (id) {
      const self = this;
      let api =
        self.$store.state.api + "tipo_avaliacao_clinica?paginated=false";

      if (self.tipos_avaliacao_clinica.length != 0) {
        return;
      }

      self.$loading(true);
      axios
        .get(api)
        .then((response) => {
          self.tipos_avaliacao_clinica = response.data.data;
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
    getTiposAuxilioLocomocao: function (id) {
      const self = this;
      let api =
        self.$store.state.api + "tipo_auxilio_locomocao?paginated=false";

      if (self.tipos_auxilio_locomocao.length != 0) {
        return;
      }

      axios
        .get(api)
        .then((response) => {
          self.tipos_auxilio_locomocao = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getTiposAcompanhante: function () {
      const self = this;
      let api =
        self.$store.state.api + "tipo_acompanhante?paginated=false&status=1";

      axios
        .get(api)
        .then((response) => {
          self.tipos_acompanhante = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getCbo: function () {
      const self = this;
      let api = self.$store.state.api + "cbos?paginated=false";

      if (self.cbos.length != 0) {
        return;
      }

      axios
        .get(api)
        .then((response) => {
          self.cbos = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    selectUnidadeOrigem() {
      const self = this;

      self.entity.origem.cep = self.entity.origem.unidade_saude_id.cep;
      self.entity.origem.endereco =
        self.entity.origem.unidade_saude_id.endereco;
      self.entity.origem.numero = self.entity.origem.unidade_saude_id.numero;
      self.entity.origem.ponto_refencia =
        self.entity.origem.unidade_saude_id.ponto_refencia;
      // self.entity.origem.complemento =
      //   self.entity.origem.unidade_saude_id.complemento;
      self.entity.origem.bairro = self.entity.origem.unidade_saude_id.bairro;
      self.entity.origem.estabelecimento_nome =
        self.entity.origem.unidade_saude_id.unidade_saude_nome;

      let api =
        self.$store.state.api +
        "municipios?municipio_id=" +
        self.entity.origem.unidade_saude_id.municipio_id;

      axios
        .get(api)
        .then((response) => {
          self.entity.origem.estado_id = response.data.data[0].uf_id;
          self.getEstadoMunicipioOrigem();
          self.entity.origem.municipio_id = response.data.data[0].municipio_id;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    selectUnidadeDestino() {
      const self = this;

      self.entity.destino.cep = self.entity.destino.unidade_saude_id.cep;
      self.entity.destino.endereco =
        self.entity.destino.unidade_saude_id.endereco;
      self.entity.destino.numero = self.entity.destino.unidade_saude_id.numero;
      self.entity.destino.ponto_refencia =
        self.entity.destino.unidade_saude_id.ponto_refencia;
      // self.entity.destino.complemento =
      //   self.entity.destino.unidade_saude_id.complemento;
      self.entity.destino.bairro = self.entity.destino.unidade_saude_id.bairro;
      self.entity.destino.estabelecimento_nome =
        self.entity.destino.unidade_saude_id.unidade_saude_nome;

      let api =
        self.$store.state.api +
        "municipios?municipio_id=" +
        self.entity.destino.unidade_saude_id.municipio_id;

      axios
        .get(api)
        .then((response) => {
          self.entity.destino.estado_id = response.data.data[0].uf_id;
          self.getEstadoMunicipioDestino();
          self.entity.destino.municipio_id = response.data.data[0].municipio_id;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    atualizarOrigem() {
      this.getUnidades();
    },
    atualizarDestino() {
      this.getUnidades();
    },
    buscarOrigem: function () {
      var self = this;

      self.naoLocalizado = false;

      if (/^[0-9]{5}-[0-9]{3}$/.test(self.entity.origem.cep)) {
        $.getJSON(
          "https://viacep.com.br/ws/" + self.entity.origem.cep + "/json/",
          function (endereco) {
            if (endereco.erro) {
              self.endereco = {};
              $("#inputNumero").focus();
              self.naoLocalizado = true;
              return;
            }

            self.entity.origem.endereco = endereco.logradouro;
            self.entity.origem.bairro = endereco.bairro;

            let api =
              self.$store.state.api +
              "municipios?municipio_codigo=" +
              endereco.ibge.substring(0, endereco.ibge.length - 1);

            axios
              .get(api)
              .then((response) => {
                self.entity.origem.estado_id = response.data.data[0].uf_id;
                self.getEstadoMunicipioOrigem();
                self.entity.origem.municipio_id =
                  response.data.data[0].municipio_id;
              })
              .catch((error) => {
                self.$message(null, error.response.data, "error");
              });

            $("#inputNumero").focus();
          }
        );
      }
    },
    buscarDestino: function () {
      var self = this;

      self.naoLocalizado = false;

      if (/^[0-9]{5}-[0-9]{3}$/.test(self.entity.destino.cep)) {
        $.getJSON(
          "https://viacep.com.br/ws/" + self.entity.destino.cep + "/json/",
          function (endereco) {
            if (endereco.erro) {
              self.endereco = {};
              $("#inputNumeroDestino").focus();
              self.naoLocalizado = true;
              return;
            }

            self.entity.destino.endereco = endereco.logradouro;
            self.entity.destino.bairro = endereco.bairro;

            let api =
              self.$store.state.api +
              "municipios?municipio_codigo=" +
              endereco.ibge.substring(0, endereco.ibge.length - 1);

            axios
              .get(api)
              .then((response) => {
                self.entity.destino.estado_id = response.data.data[0].uf_id;
                self.getEstadoMunicipioDestino();
                self.entity.destino.municipio_id =
                  response.data.data[0].municipio_id;
              })
              .catch((error) => {
                self.$message(null, error.response.data, "error");
              });

            $("#inputNumeroDestino").focus();
          }
        );
      }
    },
    buscarCPF: function () {
      var self = this;

      self.naoLocalizado = false;

      self.$loading(true);

      var cpf = self.entity.paciente.cpf.replace(/\.|\-/g, "");

      let api = self.$store.state.api + "paciente/get-cpf";
      axios
        .post(api, { cpf: cpf })
        .then((response) => {
          var data = response.data;

          if (data.erro == "CPF não encontrado") {
            self.$loading(false);
            self.$message("Atenção!", "CPF não encontrado", "warning");
            return;
          }

          self.entity.paciente.nome_paciente = data.nome;
          self.entity.paciente.nome_mae = data.nomeMae;
          self.entity.paciente.nome_pai = data.nomePai;
          self.entity.paciente.data_nascimento = moment(
            data.dataNascimento
          ).format("YYYY-MM-DD");
          self.entity.paciente.sexo = data.sexo;
          self.entity.paciente.cpf = data.cpf;
          self.entity.paciente.cns = data.cnsFederalDefinitivo;
          self.diffIdade();
          self.$loading(false);
        })
        .catch((error) => {
          self.$message("Atenção!", "CPF não encontrado", "warning");
          self.$loading(false);
        });

      // $.getJSON(
      //   "http://sigap.it4d.com.br/index.php/pesquisaCpfSus?cpf=" + cpf,
      //   function (data) {
      //     if (data.erro == "CPF não encontrado") {
      //       self.$loading(false);
      //       self.$message("Atenção!", "CPF não encontrado", "warning");
      //       return;
      //     }

      //     self.entity.paciente.nome_paciente = data.nome;
      //     self.entity.paciente.nome_mae = data.nomeMae;
      //     self.entity.paciente.nome_pai = data.nomePai;
      //     self.entity.paciente.data_nascimento = moment(
      //       data.dataNascimento
      //     ).format("YYYY-MM-DD");
      //     self.entity.paciente.sexo = data.sexo;
      //     self.entity.paciente.cpf = data.cpf;
      //     self.entity.paciente.cns = data.cnsFederalDefinitivo;
      //     self.diffIdade();
      //     self.$loading(false);
      //   }
      // );
    },
    getEntity: function (id) {
      const self = this;

      if (self.prevRoute.path.split("/")[1] == "aprovacoes") {
        self.exibir_voltar_aprovacao = true;
      }

      self.$loading(true);

      let api =
        self.$store.state.api +
        "solicitacoes/" +
        id +
        "?with[]=paciente&with[]=solicitante&with[]=cbo&with[]=unidade&with[]=avaliacoes_clinicas&with[]=cids&with[]=tipo_transporte&with[]=motivo_transporte&with[]=acompanhante&with[]=origem&with[]=destino&with[]=classificacao&with[]=unidade";

      axios
        .get(api)
        .then((response) => {
          self.entity = response.data.data[0];

          self.estilo_transporte = self.entity.tipo_transporte.tipo_solicitacao;

          self.data_procedimento_original = self.entity.data_procedimento;

          self.entity.motivo_transporte_id =
            response.data.data[0].motivo_transporte;

          self.entity.classificacao_id = response.data.data[0].classificacao;

          self.entity.flag_recorrencia =
            self.entity.flag_recorrencia == 1 ? true : false;

          console.log("1");

          if (self.entity.tipo_transporte.tipo_solicitacao == 0) {
            if (
              self.entity.paciente.incubadora == 0 ||
              self.entity.paciente.incubadora == "0" ||
              self.entity.paciente.incubadora == null
            ) {
              self.entity.paciente.incubadora = false;
            } else {
              self.entity.paciente.incubadora = true;
            }

            // if (
            //   self.entity.paciente.bomba_infusora == null ||
            //   self.entity.paciente.bomba_infusora == "0" ||
            //   self.entity.paciente.bomba_infusora == 0
            // ) {
            //   self.entity.paciente.flg_bomba_infusora = false;
            // } else {
            //   self.entity.paciente.flg_bomba_infusora = true;
            // }

            // if (
            //   self.entity.paciente.alergias == null ||
            //   self.entity.paciente.alergias == "0" ||
            //   self.entity.paciente.alergias == 0
            // ) {
            //   self.entity.paciente.flg_alergias = false;
            //   self.entity.paciente.alergias = "";
            // } else {
            //   self.entity.paciente.flg_alergias = true;
            // }

            if (
              self.entity.flag_mandado_judicial == 0 ||
              self.entity.flag_mandado_judicial == "0" ||
              self.entity.flag_mandado_judicial == null
            ) {
              self.entity.flag_mandado_judicial = false;
            } else {
              self.entity.flag_mandado_judicial = true;
            }

            if (
              self.entity.flag_vaga_zero == 0 ||
              self.entity.flag_vaga_zero == "0" ||
              self.entity.flag_vaga_zero == null
            ) {
              self.entity.flag_vaga_zero = false;
            } else {
              self.entity.flag_vaga_zero = true;
            }

            if (
              self.entity.flag_medico_unidade == 0 ||
              self.entity.flag_medico_unidade == "0" ||
              self.entity.flag_medico_unidade == null
            ) {
              self.entity.flag_medico_unidade = false;
            } else {
              self.entity.flag_medico_unidade = true;
            }

            // if (
            //   self.entity.paciente.covid == 0 ||
            //   self.entity.paciente.covid == null ||
            //   self.entity.paciente.covid == "0"
            // ) {
            //   self.entity.paciente.covid = false;
            // } else {
            //   self.entity.paciente.covid = true;
            // }

            if (
              self.entity.paciente.hipertensao == 0 ||
              self.entity.paciente.hipertensao == null ||
              self.entity.paciente.hipertensao == "0"
            ) {
              self.entity.paciente.hipertensao = false;
            } else {
              self.entity.paciente.hipertensao = true;
            }

            if (
              self.entity.paciente.transporte_bariatrico == 0 ||
              self.entity.paciente.transporte_bariatrico == null ||
              self.entity.paciente.transporte_bariatrico == "0"
            ) {
              self.entity.paciente.transporte_bariatrico = false;
            } else {
              self.entity.paciente.transporte_bariatrico = true;
            }

            if (
              self.entity.paciente.epilepsia == 0 ||
              self.entity.paciente.epilepsia == null ||
              self.entity.paciente.epilepsia == "0"
            ) {
              self.entity.paciente.epilepsia = false;
            } else {
              self.entity.paciente.epilepsia = true;
            }

            if (
              self.entity.paciente.avc == 0 ||
              self.entity.paciente.avc == null ||
              self.entity.paciente.avc == "0"
            ) {
              self.entity.paciente.avc = false;
            } else {
              self.entity.paciente.avc = true;
            }

            if (
              self.entity.paciente.diabetes == 0 ||
              self.entity.paciente.diabetes == null ||
              self.entity.paciente.diabetes == "0"
            ) {
              self.entity.paciente.diabetes = false;
            } else {
              self.entity.paciente.diabetes = true;
            }

            if (
              self.entity.paciente.etilismo == 0 ||
              self.entity.paciente.etilismo == null ||
              self.entity.paciente.etilismo == "0"
            ) {
              self.entity.paciente.etilismo = false;
            } else {
              self.entity.paciente.etilismo = true;
            }

            if (
              self.entity.paciente.asma == 0 ||
              self.entity.paciente.asma == null ||
              self.entity.paciente.asma == "0"
            ) {
              self.entity.paciente.asma = false;
            } else {
              self.entity.paciente.asma = true;
            }

            if (
              self.entity.paciente.iam == 0 ||
              self.entity.paciente.iam == null ||
              self.entity.paciente.iam == "0"
            ) {
              self.entity.paciente.iam = false;
            } else {
              self.entity.paciente.iam = true;
            }

            console.log("4");

            if (self.entity.paciente.bomba_infusora_medicamentos != null) {
              self.entity.paciente.bomba_infusora_medicamentos =
                self.entity.paciente.bomba_infusora_medicamentos.split(", ");
            }

            console.log("5");
            self.entity.unidade_saude_id = response.data.data[0].unidade;

            console.log("6");
            var cids = self.entity.cids;
            self.entity.cids = [];

            cids.forEach((element) => {
              self.entity.cids.push(element.cid);
            });

            var avaliacoes = self.entity.avaliacoes_clinicas;
            self.entity.avaliacoes_clinicas = [];

            avaliacoes.forEach((element) => {
              console.log(element);
              var obj = {
                avaliacao: element.avaliacao_clinica_descricao,
                datetime: element.data_registro,
                usuario: element.usuario,
              };
              self.entity.avaliacoes_clinicas.push(obj);
            });

            console.log("5");
            self.entity.paciente.ficha_clinica.forEach((element) => {
              self.tipos_avaliacao_clinica.forEach((tiac) => {
                if (
                  tiac.tipo_avaliacao_clinica_id ==
                  element.tipo_avaliacao_clinica_id
                ) {
                  tiac.value = true;
                }
              });
            });
          } else {
            self.getTiposCuidado();
            self.getMateriais();
            self.getCuidados();
            self.entity.material = {};
            self.entity.material.materiais = [];
          }

          if (self.entity.flag_retorna_origem == 0) {
            self.entity.flag_retorna_origem = false;
          } else {
            self.entity.flag_retorna_origem = true;
          }

          if (self.entity.flag_recorrencia == 1) {
            self.entity.flag_recorrencia = true;
            var dias = self.entity.dias_semana.split(",");
            self.entity.dias_semana = [];
            dias.forEach((element) => {
              self.dias_semana.forEach((el) => {
                if (el.id == element) {
                  self.entity.dias_semana.push(el);
                }
              });
            });
          } else {
            self.entity.flag_recorrencia = false;
          }

          self.getMotivos();
          self.getEstadoMunicipioOrigem();
          self.getEstadoMunicipioDestino();
          if (self.entity.destino && self.entity.destino.estabelecimento_nome) {
            self.destino_outros = true;
          } else {
            self.destino_outros = false;
          }

          if (self.entity.origem && self.entity.origem.estabelecimento_nome) {
            self.origem_outros = true;
          } else {
            self.origem_outros = false;
          }

          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
    getMateriais() {
      const self = this;
      let api =
        self.$store.state.api +
        "transporte_sanitario_produtos?with[]=produto&paginated=false&transporte_sanitario_id=" +
        self.entity.transporte_sanitario_id;

      axios
        .get(api)
        .then((response) => {
          var materiais = response.data.data;
          materiais.forEach((element) => {
            var obj = element.produto;
            self.entity.material.materiais.push(obj);
          });
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getCuidados() {
      const self = this;
      let api =
        self.$store.state.api +
        "ts_tipo_cuidados?with[]=tipo&paginated=false&transporte_sanitario_id=" +
        self.entity.transporte_sanitario_id;

      axios
        .get(api)
        .then((response) => {
          var cuidados = response.data.data;

          cuidados.forEach((element) => {
            self.tipos_cuidado.forEach((tiac) => {
              if (tiac.tipo_cuidado_id == element.tipo_cuidado_id) {
                tiac.value = true;
              }
            });
          });
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getEstadoMunicipioOrigem() {
      const self = this;
      let api =
        self.$store.state.api +
        "municipios?orderByAsc=municipio_nome&paginated=false&uf_id=" +
        self.entity.origem.estado_id;

      axios
        .get(api)
        .then((response) => {
          self.municipios_origem = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getEstadoMunicipioDestino() {
      const self = this;
      let api =
        self.$store.state.api +
        "municipios?orderByAsc=municipio_nome&paginated=false&uf_id=" +
        self.entity.destino.estado_id;

      axios
        .get(api)
        .then((response) => {
          self.municipios_destino = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getUFs: function () {
      const self = this;
      let api =
        self.$store.state.api + "ufs?orderByAsc=uf_nome&paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.ufs = response.data.data;
          self.$loading(false);
          self.isLoading = false;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
          self.isLoading = false;
        });
    },
    getNumero() {
      const self = this;
      let api = self.$store.state.api + "solicitacoes/get-numero";

      axios
        .post(api)
        .then((response) => {
          self.entity.numero_solicitacao = response.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    setPages() {
      const self = this;

      console.log("estilo", self.estilo_transporte);

      setTimeout(function () {
        if (self.configuracoes.permissoes) {
          if (self.estilo_transporte == 0) {
            if (self.$store.state.user.perfil_id == 2) {
              if (self.inverse) {
                self.pages_available = [2, 1, 3, 4, 6];
              } else {
                self.pages_available = [1, 2, 3, 4, 6];
              }
              self.page = 1;
              self.finish_page = 6;
              self.initial_page = 1;
            }

            if (self.$store.state.user.perfil_id == 5) {
              //Perfil de TARM
              if (self.inverse) {
                self.pages_available = [2, 1, 6];
              } else {
                self.pages_available = [1, 2, 6];
              }
              self.page = 1;
              self.finish_page = 6;
              self.initial_page = 1;
            }

            if (self.$store.state.user.perfil_id == 6) {
              //Perfil de médico
              self.pages_available = [2, 1, 3, 4, 6];
              self.page = 1;
              self.finish_page = 6;
              self.initial_page = 4;
            }

            if (self.$store.state.user.perfil_id == 21) {
              //Perfil de médico
              self.pages_available = [2, 1, 3, 4, 6];
              self.page = 1;
              self.finish_page = 6;
              self.initial_page = 4;
            }

            if (self.$store.state.user.perfil_id == 7) {
              if (self.inverse) {
                self.pages_available = [2, 1, 3, 6];
              } else {
                self.pages_available = [1, 2, 3, 6];
              }
              self.page = 1;
              self.finish_page = 6;
              self.initial_page = 1;
            }

            if (self.$store.state.user.perfil_id == 10) {
              if (self.inverse) {
                self.pages_available = [2, 1, 3, 4, 6];
              } else {
                self.pages_available = [1, 2, 3, 4, 6];
              }
              self.page = 1;
              self.finish_page = 6;
              self.initial_page = 1;
            }
          } else {
            self.pages_available = [1, 5, 6];
          }
        } else {
          if (self.inverse) {
            self.pages_available = [2, 1, 3, 4, 6];
          } else {
            self.pages_available = [1, 2, 3, 4, 6];
          }
        }

        if (self.inverse == true) {
          self.page = 2;
          self.initial_page = 2;
        }

        self.navbar_loaded = true;
      }, 1000);
    },
    dataInexistente() {
      const self = this;
      if (!self.data_aniversario.data_inexistente) {
        self.entity.paciente.data_nascimento = "1970-01-01";
        self.data_aniversario.data_inexistente = true;
        self.data_aniversario.texto_data = "Adicionar Informação";
      } else {
        self.entity.paciente.data_nascimento = "";
        self.data_aniversario.data_inexistente = false;
        self.data_aniversario.texto_data = "Informação Inexistente";
      }
    },
  },
  mounted: function () {
    const self = this;

    let id = self.$route.params.id;
    if (id) {
      self.getEntity(id);
      self.getConfiguracoes();
    } else {
      self.$loading(false);
      self.entity.data_solicitacao = moment().format("YYYY-MM-DD HH:mm:ss");
      self.getNumero();
      self.getConfiguracoes();
    }

    self.getTiposTransporte();
    self.getTiposDomicilio();

    // self.getTiposAvaliacaoClinica(); --- Feito
    // self.getTiposAuxilioLocomocao(); --- Feito
    // self.getTiposAcompanhante(); --- Feito
    self.getUnidades();
    self.getUFs();
    self.getTiposUnidadesSaude();
    // self.getCbo();     ---Feito
  },
  components: {
    vSelect,
    VueSimpleSuggest,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}

tr.obesidade1 {
  color: #fff;
  font-weight: bold;
  background-color: #ff4500;
}

tr.obesidade2 {
  color: #fff;
  font-weight: bold;
  background-color: #ff0000;
}

tr.obesidade3 {
  color: #fff;
  font-weight: bold;
  background-color: #e30505;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}

.b-card-subtitle {
  font-size: 18px;
  font-weight: bold;
}

.sbx-google__submit {
  right: 0;
  left: inherit;
  border-radius: 0 3px 3px 0;
  display: none !important;
}

.btn-approve {
  position: fixed;
  width: 20%;
  /* height: 25px; */
  bottom: 40px;
  right: 39%;
  background-color: #3387f4;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  z-index: 1000;
  box-shadow: 5px 5px 7px #888;
}

.btn-approve:hover {
  background-color: #2781f7;
  color: #fff;
}

.classificacao {
  width: 35px;
  height: 35px;
  border-radius: 3px;
}
</style>